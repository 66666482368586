import React from "react";
import carImage1 from '../../Images/car_img_1.png';
import carImage2 from '../../Images/car_img_2.png';
import carImage3 from '../../Images/car_img_3.png';
import carImage4 from '../../Images/car_img_4.png';
import infoImg from '../../Images/info_img.png';


const DescriptionPage = () => {

    return (<>
        <div className="flex flex-col md:flex-row justify-center items-center pt-20 mx-4">
            <div className="grid grid-cols-1 md:grid-cols-2 max-w-[1200px] gap-8 md:gap-16">
                <div data-aos="fade-left">
                    <h1 className="text-[25px] leading-10 font-[500] py-2 text-customfontBlack font-headingFont ">India's Largest Intercity <br /> and Local Cab Services</h1>
                    <p className="text-[15px] py-5 font-issme text-justify" >We are Hindustan Taxi Service Car Rentals, an online cab booking aggregator, providing customers with reliable and premium Intercity and Local car rental services. Over the last decade, we are uniquely placed as the largest chauffeur driven car rental company in India in terms of geographical reach.</p>
                </div>
                <div  data-aos="fade-right" className="2xl:px-10 xl:px-10 lg:px-10 md:px-6 sm:px-6 smmin:px-4 maxsm:px-4 minsm:px-2 smsm:px-2">
                    <div className="grid grid-cols-2">
                        <img src={carImage1} alt="carImage" />
                        <img src={carImage2} alt="carImage" />
                    </div>
                    <div className="grid grid-cols-2">
                        <img src={carImage3} alt="carImage" />
                        <img src={carImage4} alt="carImage" />
                    </div>
                </div>
            </div>
        </div>

        <div className="flex justify-center items-center mx-4 mt-9">
            <div className="max-w-[1200px]  ">
                <div  data-aos="fade-right">
                    <h1 className="text-[25px] leading-10 py-2 font-headingFont text-customfontBlack"> Exploring India, one road trip at a time </h1>
                    <div className="grid 2xl:grid-cols-2 smmin:grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-8">
                        <img src={infoImg} className="mt-2 border border-gray-500 rounded-2xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] " />
                        <div>
                            <p className="text-[15px]  font-normalFont text-justify">To us, a road trip is one of the most exhilarating ways to travel the length and breadth of India. There's always something to look at, something to explore and to experience. Because we love travelling by road so much, we've been striving to make sure you have a great experience too. We wanted more of you to go on a road trip, and more of you to experience the same joys of travel that we do. Instead of driving, why not sit back and take our chauffeur driven cabs on your next vacation? We believe that the time you spend on your vacation should be entirely yours. So now, we are in 2000+ cities across India - to help you travel to wherever your heart desires.</p>
                            <p className="text-[15px] py-3 font-normalFont lg:block md:hidden text-justify">We love that you're free to stop to breathe in clean air, learn about cultures and taste local food when you travel by cabs. We love that these wholesome experiences make travelling better and enrich our lives. We live for the surprises we find on road trips.</p>
                            <p className="text-[15px] py-3 font-normalFont lg:block md:hidden text-justify">No city is too big or too small. We know travellers and wanderers like us are everywhere. You live near Khajuraho, you live near Aleppey, and you live near Alibag and near Tranquebar. We want you to visit them all. Pack your bags every weekend and explore everything there is to see around you.</p>

                        </div>
                    </div>
                </div>
                <div  data-aos="fade-left">
                <p className="text-[15px] py-3 font-normalFont lg:hidden md:block text-justify">We love that you're free to stop to breathe in clean air, learn about cultures and taste local food when you travel by cabs. We love that these wholesome experiences make travelling better and enrich our lives. We live for the surprises we find on road trips.</p>
                <p className="text-[15px] py-3 font-normalFont lg:hidden md:block text-justify">No city is too big or too small. We know travellers and wanderers like us are everywhere. You live near Khajuraho, you live near Aleppey, and you live near Alibag and near Tranquebar. We want you to visit them all. Pack your bags every weekend and explore everything there is to see around you.</p>
                <p className="text-[15px] py-3 font-normalFont text-justify ">To make planning your vacation easier, you can book a cab with ease on our website, or call us on +91 76000 60604 if you'd like to discuss your itinerary with our executives in detail. When you book an outstation cab with us, we'll send you a travel kit and help you plan your itinerary. We also have a handy cab booking app that will further reduce the hassles of booking a trip with us. Our expert drivers will guide you through some of the best experiences India has to offer. From the time you make a booking with us, to the time you get back home, we'll make sure you have a great road trip.</p>
                <h1 className="py-5 font-bold text-[16px] text-customfontBlack ">Trust us when we say: Travel begins with Hindustan Rental Taxi.</h1>
                </div>
                <div>
                    <h1 className="text-[25px] leading-10 font-[650] py-2 text-customfontBlack " data-aos="fade-right">No matter where you travel - we've got a cab for you</h1>


                    <p className="text-[15px] py-5 font-normalFont text-justify " data-aos="fade-right">Planning a weekend getaway? Our outstation cab services will help you explore the best destinations, visit all the must-see places and taste the best local food. Did you just land at an airport or railway station closest to your destination? No problem! You can use our airport taxi, the transit pick up service to cover the last mile. We'll get you to your destination and show you some of the best sights along the way. Planning on traveling home for a family get-together? Try our newly introduced one-way cab services - no matter where you live, get dropped to your hometown by paying only one-side fare. Decided to take a personal day and spend the whole day exploring your city? Our local taxi packages will help you explore the best places to eat and drink at, some of the city's majestic monuments, greenest parks and oldest temples. You'll never have to worry about an empty travel itinerary again. Are you an offbeat traveller? Do you just hit the road and decide to take it from there? We offer one-way drops on several routes, in case you only want to be dropped to a destination and don't want to look back.</p>

                </div>

            </div>
        </div>
    </>);
};

export default DescriptionPage;
