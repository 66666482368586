import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaRegClock } from 'react-icons/fa'; // Importing icons for better UX

const BlogGrid = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay for better engagement
        autoplaySpeed: 3000, // Time between slides
        arrows: false // Remove arrows from the slider
    };

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/getblog');
                const data = await response.json();
                setBlogs(data.data.contacts); 
                setLoading(false);
            } catch (error) {
                console.error('Error fetching blog data:', error);
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-10">
                <h1 className="text-2xl text-center mb-10 text-black font-[600]">Hindustan Taxi Service Blogs</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                    {blogs.map((item) => (
                        <div 
                            key={item._id} 
                            className="shadow-md rounded-lg overflow-hidden cursor-pointer p-3 bg-gray-200"
                            onClick={() => navigate(`/blog/${item._id}`, { state: { blog: item } })}
                        >
                            <Slider {...settings}>
                                {item.image.map((img, index) => (
                                    <div key={index}>
                                        <img
                                            src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${img}`}
                                            className="w-full h-60 object-cover rounded-lg"
                                            alt={item.title}
                                        />
                                    </div>
                                ))}
                            </Slider>
                            <div className="py-6 px-2 mt-4 ">
                               
                                <h1 className="text-xl font-semibold mb-2 text-gray-800 ">
                                    {item.name}
                                </h1>
                                <p className="text-black text-sm py-1 text-justify">{item.short_description}</p>
                                <div className='flex justify-between'>
                                <button 
                                    className="mt-4 bg-customBlue text-sm text-white py-2 px-4 rounded w-[50%]"
                                    onClick={() => navigate(`/blog/${item._id}`, { state: { blog: item } })}
                                >
                                    Read More
                                </button>
                                <div className="flex items-center text-black text-sm mb-2 mt-3">
                                    <FaRegClock className="mr-1" />
                                    {new Date(item.date).toLocaleDateString()}
                                </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogGrid;
