import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS library
import '../../App.css';

import dudhni from '../../Images/dudhni.png';
import dwarka from '../../Images/dwarka.png';
import goa from '../../Images/goa.png';
import lonavala from '../../Images/lonavala.png';
import panchgani from '../../Images/panchgani.png';
import saputara from '../../Images/saputara.png';
import sasangir from '../../Images/sasangir.png';
import somnath from '../../Images/somnath.png';
import statu_of_unity from '../../Images/statu_of_unity.png';

const Sliders = () => {
  const sliderData = [
    { image: dudhni },
    { image: dwarka },
    { image: goa },
    { image: lonavala },
    { image: panchgani },
    { image: saputara },
    { image: sasangir },
    { image: somnath },
    { image: statu_of_unity }
  ];

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with duration and once option
  }, []);

  if (!Array.isArray(sliderData) || sliderData.length <= 0) {
    return null;
  }

  // Slider settings with responsiveness
  const settings = {
    arrows: false,
    dots: true,
    swipeToSlide: true,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center items-center mx-4 smsm:mt-[33rem] minsm:mt-[34rem] 
    maxsm:mt-[33rem] sm:mt-[20rem] md:mt-[20rem] lg:mt-[20rem] xl:mt-[14rem]">
      <Slider {...settings} className="w-full max-w-[1200px]">
        {sliderData.map((slide, index) => (
          <div key={index} className="px-2">
            <img
              src={slide.image}
              alt={`slider-${index}`}
              className="w-full h-auto rounded-[20px] object-cover"
               // AOS animation attribute
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Sliders;
