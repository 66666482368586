import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import axios from 'axios';
import { GoogleMap, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';

const containerStyle = {
  height: "100%",
  width: "100%",
};

function AirportDetails() {
  const [formData, setFormData] = useState({});
  const [airportDetails, setAirportDetails] = useState([]);
  const [distance, setDistance] = useState({});
  const [directionsResponse1, setDirectionsResponse1] = useState(null);
  const [fromPosition, setFromPosition] = useState(null);
  const [toPosition, setToPosition] = useState(null);
  const [formattedTime, setFormattedTime] = useState(''); 

  const location = useLocation();
  const navigate = useNavigate();
  const API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

  useEffect(() => {
    // Extract the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);

    
    // Decode and parse the data
    const type = queryParams.get('type');
    const from = decodeURIComponent(queryParams.get('from'));
    const to = decodeURIComponent(queryParams.get('to'));
    const person = decodeURIComponent(queryParams.get('person'));
    const pickupDate = decodeURIComponent(queryParams.get('pickupDate'));
    const pickupTime = decodeURIComponent(queryParams.get('pickupTime'));
  
console.log("pickupTime==",pickupTime)

    // Set the form data in state
    setFormData({
      type,
      from,
      to,
      person,
      pickupDate,
      pickupTime
    });
  }, [location.search]);

  // Fetch airport details when formData.type changes


  console.log('formData =>', formData);

  const fetchDirections1 = useCallback(debounce((options) => {
    if (options) {
      new window.google.maps.DirectionsService().route(options, (result, status) => {
        if (status === 'OK') {
          setDirectionsResponse1(result);
        } else {
          console.error('Directions request 1 failed due to ' + status);
        }
      });
    }
  }, 1000), []);

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const addressFrom = formData.from;
        const addressTo = formData.to;

        // Fetch coordinates for the 'from' location
        if (addressFrom) {
          const responseFrom = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(addressFrom)}&key=${API_KEY}`
          );
          if (responseFrom.data.results.length > 0) {
            const { lat, lng } = responseFrom.data.results[0].geometry.location;
            setFromPosition({ lat, lng });
          } else {
            console.error('From location not found');
          }
        }

        // Fetch coordinates for the 'to' location
        if (addressTo) {
          const responseTo = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(addressTo)}&key=${API_KEY}`
          );
          if (responseTo.data.results.length > 0) {
            const { lat, lng } = responseTo.data.results[0].geometry.location;
            setToPosition({ lat, lng });
          } else {
            console.error('To location not found');
          }
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    if (formData.from && formData.to) {
      fetchCoordinates();
    }
  }, [formData.from, formData.to]);

  console.log('toPosition =>', toPosition);
  console.log('fromPosition =>', fromPosition);

  useEffect(() => {
    if (fromPosition && toPosition) {

      fetchDirections1({
        origin: { lat: fromPosition.lat, lng: fromPosition.lng },
        destination: { lat: toPosition.lat, lng: toPosition.lng },
        optimizeWaypoints: true,
        travelMode: 'DRIVING',
      });
    }
  }, [fetchDirections1, fromPosition, toPosition]);



  useEffect(() => {

    if (formData.type) {
      const apiUrl = `https://bhadegadiapi.taxiwalataxi.in/api/v1/searchalldata?type=${formData.type}&seats=${formData.person}`;
      const fetchAirportDetails = async () => {
        try {
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          // Check if the response is successful
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Parse the JSON response
          const data = await response.json();
          setAirportDetails(data?.data?.taxiDetails || []);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      fetchAirportDetails();
    }
  }, [formData.type]);

  // Fetch distance when formData.from and formData.to change
  useEffect(() => {
    if (formData.from && formData.to) {
      const apiUrl = 'https://bhadegadiapi.taxiwalataxi.in/api/v1/adddistance';
      const postData = {
        cityname1: formData.from,
        cityname2: formData.to
      };

      const fetchDistance = async () => {
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          // Check if the response is successful
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          console.log('distance =>', distance);
          // Parse the JSON response
          const data = await response.json();
          const roundedDistance = data?.result?.distance || 0;
const dist = Math.ceil(roundedDistance);

          // Calculate price based on distance
          let calculatedDistance;
          if (dist > 0 && dist < 50) {
            calculatedDistance = 125;
          } else if (dist >= 50 && dist < 100) {
            calculatedDistance = dist * 2.5;
          } else if (dist >= 100 && dist < 150) {
            calculatedDistance = dist * 2;
          } else if (dist >= 150 && dist <= 300) {
            calculatedDistance = 300;
          } else {
            calculatedDistance = dist; // Default case
          }

          setDistance({
            ...data,
            result: {
              ...data.result,
              calculatedDistance
            }
          });
        } catch (error) {
          console.error('Error:', error);
        }
      };
      fetchDistance();
    }
  }, [formData.from, formData.to]);

  const handleViewDealClick = (car) => {

    const actualRatePerKM = car.airportTrip.actualRatePerKM || 0;
    const discountRatePerKM = car.airportTrip.discountRatePerKM || 0;
    const distanceKM = distance?.result?.calculatedDistance || 0;
    const actualPrice = distanceKM * actualRatePerKM;
    const discountPrice = distanceKM * discountRatePerKM;
    const km = car.airportTrip.actualRatePerKM;
    const totalKM = distance.result.calculatedDistance;
    const type = 'Airport Trip';
    const distancess = distanceKM;
    const date = formData.pickupDate;
    const times = formData.pickupTime;

    const details = [
      {
        'inclusions': [{ '0': 'Fuel Charges' }, { '1': 'Toll/State tax' }]
      },
      {
        'Exclusions': [`Pay ₹${km}/km after ${totalKM}km`, 'Multiple Pickups/drops', 'Airport Entry/Parking', 'Driver Allowance']
      },
      {
        'Terms and conditions': [
          'Your Trip has a KM limit. If your usage exceeds these limits, you will be charged for the excess KM used.',
          'The Airport Entry/Parking charge, if applicable, is not included in the fare and will be charged extra.',
          'Your trip includes one pick up in Pick-up city and one drop to destination city, It does not included within city travel.',
          'If your trip has Hill Climbs, cab AC many be switched off during such climbs.'
        ]
      }
    ];

    navigate(`/car-booking/${car._id}`, {
 
      state: {
        car,
        formData,
        actualPrice,
        discountPrice, details, 
        type, 
        distancess,
        date,
        times

      }
    });
  };
  console.log('formData =>', formData);

  return (<>

    <div className=" mx-auto px-4 py-8">
    <div className='bg-white  border border-gray-200 shadow-lg container mx-auto rounded-2xl p-4'>
          <div className='grid  sm:grid-cols-2 lg:grid-cols-7 gap-4'>
            {/* Booking Type */}
            <div className=''>
              <div className='flex items-center'>
                {/* Icon can be added here */}
                <h1 className='text-md font-semibold text-gray-600'>Booking Type</h1>
              </div>
              <h1 className='text-sm font-bold text-customBlue'> Eight Hours Trip</h1>
            </div>

            {/* Pickup Date */}
            <div className=''>
              <div className='flex items-center gap-2'>
                {/* Icon can be added here */}
                <p className='text-md font-semibold text-gray-600'>Pick-up Date</p>
              </div>
              <p className='text-sm font-bold text-customBlue'>
                {new Date(formData.pickupDate).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </p>
            </div>

            {/* Pickup Time */}
            <div className=''>
              <div className='flex items-center gap-2'>
                {/* Icon can be added here */}
                <p className='text-md font-semibold text-gray-600'>Pick-up Time</p>
              </div>
              <p className='text-sm font-bold text-customBlue'>{formData.pickupTime}</p>
            </div>

            <div className='col-span-2'>
              <div className='flex items-center gap-2'>
                {/* Icon can be added here */}
                <p className='text-md font-semibold text-gray-600'>Pickup-from</p>
              </div>
              <p className='text-sm font-bold text-customBlue'>{formData.from}</p>
            </div>

            <div className='col-span-2'>
              <div className='flex items-center gap-2'>
                {/* Icon can be added here */}
                <p className='text-md font-semibold text-gray-600'>Pickup-from</p>
              </div>
              <p className='text-sm font-bold text-customBlue'>{formData.to}</p>
            </div>

          </div>

        </div>


      <div className='grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10 mt-5'>
        <div className="">
          <h2 className="text-lg font-semibold mb-4">Available Cars</h2>
          {airportDetails.length > 0 ? (
            airportDetails.map((car, index) => {

              const actualRatePerKM = car.airportTrip.actualRatePerKM || 0;
              const discountRatePerKM = car.airportTrip.discountRatePerKM || 0;
              const distanceKM = distance?.result?.calculatedDistance || 0;
              const actualPrice = distanceKM * actualRatePerKM;
              const discountPrice = distanceKM * discountRatePerKM;

              return <>
                <div key={index} className="mx-auto my-8  rounded-[10px] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                  <div className="grid smmin:grid-cols-3 sm:grid-cols-7 smmin:gap-0 sm:gap-4">
                    <div className='p-4  smmin:col-span-1 sm:col-span-3'>
                      <div>
                        <h2 className="text-lg font-bold pb-3">{car.Name}</h2>
                        <div className="flex items-center mt-2 pb-3">
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="#000"
                              style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M9.572 2.082A1.384 1.384 0 009 3.33v5.897A2.792 2.792 0 0012 12a2.791 2.791 0 003-2.774v-5.86a1.435 1.435 0 00-.608-1.285A4.966 4.966 0 0012 1.5a5.122 5.122 0 00-2.428.582zM4.145 16.865A17.3 17.3 0 0112 15a17.608 17.608 0 017.893 1.867A2 2 0 0121 18.75v.75a3.226 3.226 0 01-3 3H6a3.226 3.226 0 01-3-3v-.75a2.073 2.073 0 011.145-1.885z"></path></svg>
                            <span className="text-black text-[13px] font-[600] ml-1" > {car.seats}</span>
                          </div>
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M12 0a1.5 1.5 0 00-1.5 1.5v2.379l-1.94-1.94a1.5 1.5 0 10-2.157 2.085l.037.037 4.06 4.06V10.5H8.121l-4.06-4.06a1.5 1.5 0 00-2.159 2.083l.037.037 1.94 1.94H1.5a1.5 1.5 0 000 3h2.379l-1.94 1.94a1.5 1.5 0 002.122 2.12l4.06-4.06H10.5v2.379l-4.06 4.06a1.5 1.5 0 002.12 2.122l1.94-1.94V22.5a1.5 1.5 0 003 0v-2.379l1.94 1.94a1.5 1.5 0 002.12-2.122l-4.06-4.06V13.5h2.379l4.06 4.06a1.5 1.5 0 002.122-2.12l-1.94-1.94H22.5a1.5 1.5 0 000-3h-2.379l1.94-1.94a1.5 1.5 0 00-2.122-2.12l-4.06 4.06H13.5V8.121l4.06-4.06a1.5 1.5 0 00-2.12-2.122L13.5 3.88V1.5A1.5 1.5 0 0012 0z"></path></svg>
                            <span className="text-black text-[13px] font-[600] ml-1"> {car.IsAcCar}</span>
                          </div>
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><g clip-path="url(#a)"><path d="M3.75 2.248V12H3a2.25 2.25 0 00-2.25 2.25v6A2.25 2.25 0 003 22.5v.375a1.125 1.125 0 002.25 0V22.5h4.5v.375a1.125 1.125 0 002.25 0V22.5a2.25 2.25 0 002.25-2.25v-6A2.25 2.25 0 0012 12h-.75V2.25A2.25 2.25 0 009 0H6a2.25 2.25 0 00-2.25 2.248zm5.625.002c.207 0 .375.168.375.375V12h-4.5V2.625c0-.207.168-.375.375-.375h3.75zM15.75 13.5a3 3 0 00-2.626-2.977c-.205-.025-.374-.19-.374-.398V2.248A2.25 2.25 0 0115 0h3a2.25 2.25 0 012.25 2.25V6H21a2.25 2.25 0 012.25 2.25v12A2.25 2.25 0 0121 22.5v.375a1.125 1.125 0 01-2.25 0V22.5h-2.625a.375.375 0 01-.375-.375V13.5zm2.625-11.25h-3.75a.375.375 0 00-.375.375V6h4.5V2.625a.375.375 0 00-.375-.375z"></path></g></svg>
                            <span className="text-black text-[13px] font-[600] ml-1"> {car.BootSpace}</span>
                          </div>
                        </div>

                        <div className="flex items-center">
                          <span className="text-yellow-600 font-[600] text-sm">up to {distance?.result?.calculatedDistance || 'Calculating...'} km</span>
                          <span className="ml-2 text-gray-500 font-[600] text-sm">Good deal</span>
                        </div>
                      </div>

                      <div className="mt-2 text-gray-500">
                        <span className='font-[600] text-sm'>All Inclusive</span>
                      </div>
                    </div>

                    <div className="relative mr-2  smmin:col-span-1 sm:col-span-2 ">
                      {/* <p class="text-md font-semibold w-[84px] mt-2 ml-[58px] py-2 px-1 my-0 bg-[#D10D25] text-white shadow-lg text-center before:content-[''] before:absolute before:w-0 before:h-0 before:border-t-[16px] before:border-b-[16px] before:border-r-[16px] before:border-l-0 before:border-t-transparent before:border-b-transparent before:border-r-[#D10D25] before:right-full  before:top-2 before:right-[83px]">Toll Inclusion</p> */}
                      <div>
                        <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}`} alt="Jeep Compass" className="w-full h-full mt-[-10px] sm:mt-6 " />
                      </div>
                    </div>

                    <div className="border-l smmin:col-span-1 sm:col-span-2  border-gray-400 bg-white  justify-between rounded-r-[16px] p-4 h-full sm:mt-0 mt-[-10px] " style={{ flexFlow: 'wrap' }}>
                      <div className='text-center flex sm:flex-col flex-row sm:mt-[0px] mt-[-12px] justify-center gap-5 sm:gap-0'>
                        <div className="text-xl font-bold text-gray-800 py-1">

                          ₹ {Math.round(actualPrice)}
                        </div>
                        <div className='py-1'>
                          <del className="text-md font-semibold text-red-500"> ₹ {Math.round(discountPrice)}</del>
                        </div>
                      </div>
                      <p className='text-center font-bold text-sm text-green-700'>Toll-Tax Inclusion</p>
                      {/* <button className="self-center mt-auto bg-customBlue mt-[-10px] font-[600] text-white py-1 px-4 text-[15px] w-full rounded">View Deal</button> */}
                      <button onClick={() => handleViewDealClick(car, actualPrice, discountPrice)} className="self-center mt-auto bg-customBlue sm:mt-[26px] mt-[7px] font-[600] text-white py-1 px-4 text-[15px] w-full rounded">View Deal</button>
                    </div>

                  </div>
                </div>
              </>
            }
            )
          ) : (
            <p>No car details available.</p>
          )}
        </div>

        <div>
          <h1 className='text-xl font-semibold '>Direction</h1>
          <div className='bg-white rounded-lg p-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-[445px] mt-7' >

            <GoogleMap
              googleMapsApiKey={API_KEY}
              mapContainerStyle={containerStyle}
              center={formData.fromPlace || { lat: 0, lng: 0 }}
              zoom={10}
            >
              {directionsResponse1 && (
                <DirectionsRenderer directions={directionsResponse1} />
              )}
            </GoogleMap>

          </div>
        </div>
      </div>
    </div>



  </>);
}

export default AirportDetails;
