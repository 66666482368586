// import React, { useState, useEffect } from 'react';
// import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';

// const containerStyle = {
//     width: '100%',
//     height: '400px'
// };
// const MemoryImg = () => {
//     const [directions, setDirections] = useState(null);
//     const [locationA, setLocationA] = useState(null);

//     const placeA = "Surat";

//     const geocodePlace = (place, callback) => {
//         const geocoder = new window.google.maps.Geocoder();
//         geocoder.geocode({ address: place }, (results, status) => {
//             if (status === window.google.maps.GeocoderStatus.OK) {
//                 const location = results[0].geometry.location;
//                 callback({ lat: location.lat(), lng: location.lng() });
//             } else {
//                 console.error(`Geocode failed for ${place}: ${status}`);
//             }
//         });
//     };

//     useEffect(() => {
//         if (window.google && window.google.maps) {
//             geocodePlace(placeA, setLocationA);
//         }
//     }, []);

//     useEffect(() => {
//         if (locationA) {
//             const directionsService = new window.google.maps.DirectionsService();
//             directionsService.route(
//                 {
//                     origin: locationA,
//                     travelMode: window.google.maps.TravelMode.DRIVING
//                 },
//                 (result, status) => {
//                     if (status === window.google.maps.DirectionsStatus.OK) {
//                         setDirections(result);
//                     } else {
//                         console.error(`Error fetching directions: ${result}`);
//                     }
//                 }
//             );
//         }
//     }, [locationA]);

//     return (

//             <GoogleMap  googleMapsApiKey="AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI"
//                 mapContainerStyle={containerStyle}
//                 center={locationA || { lat: 21.1702, lng: 72.8311 }}  // Default to Surat if locationA isn't available yet
//                 zoom={12}
//             >
//                 {locationA && <Marker position={locationA} />}
//                 {directions && <DirectionsRenderer directions={directions} />}
//             </GoogleMap>

//     );
// }

// export default MemoryImg;




import React, { useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import RawTool from '@editorjs/raw';
import CodeTool from '@editorjs/code';

const EditorComponent = () => {
  const editorInstance = useRef(null);
  const [rawHtml, setRawHtml] = useState(''); // State to store raw HTML
  const [codeSnippet, setCodeSnippet] = useState(''); // State to store code snippet

  useEffect(() => {
    // Initialize Editor.js
    editorInstance.current = new EditorJS({
      holder: 'editorjs', // The ID of the div where Editor.js is rendered
      tools: {
        raw: RawTool, // Raw HTML Tool
        code: CodeTool, // Code Tool
      },
      autofocus: true,
    });

    return () => {
      // Clean up the Editor.js instance on component unmount
      if (editorInstance.current) {
        // editorInstance.current.destroy();
      }
    };
  }, []);

  // Function to save content and display raw HTML and code snippets
  const handleSave = async () => {
    if (editorInstance.current) {
      const outputData = await editorInstance.current.save();
      
      // Find the raw block in the output data
      const rawBlock = outputData.blocks.find(block => block.type === 'raw');
      if (rawBlock) {
        setRawHtml(rawBlock.data.html);
      }

      // Find the code block in the output data
      const codeBlock = outputData.blocks.find(block => block.type === 'code');
      if (codeBlock) {
        setCodeSnippet(codeBlock.data.code);
      }
    }
  };

  return (
    <div>
      <h2>Editor.js with Raw HTML and Code Snippet Output</h2>
      <div id="editorjs" style={{ border: '1px solid #ccc', padding: '10px', minHeight: '300px' }}></div>
      
      <button onClick={handleSave} style={{ marginTop: '10px' }}>
        Save and Display Raw HTML and Code
      </button>

      <h3>Raw HTML Output:</h3>
      <div
        dangerouslySetInnerHTML={{ __html: rawHtml }} // Render the raw HTML
        style={{ border: '1px solid #ddd', padding: '10px', marginTop: '10px', backgroundColor: '#f9f9f9' }}
      />

      <h3>Rendered Images (if any):</h3>
      <div>
        {rawHtml && rawHtml.includes('<img') ? (
          <div dangerouslySetInnerHTML={{ __html: rawHtml }} />
        ) : (
          <p>No image tags found in the input.</p>
        )}
      </div>

      <h3>Code Snippet Output:</h3>
      <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', border: '1px solid #ddd' }}>
        {codeSnippet || 'No code snippet found.'}
      </pre>
    </div>
  );
};

export default EditorComponent;
