import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Success() {

  // Assuming you have the `orderId` and `amount` coming from the URL or some other source
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const orderId = query.get('orderId'); // Get 'orderid' from the URL
  const amount = query.get('amount')/100;// Replace this with dynamic value
  const paymentMethod = query.get('paymentMethod');// Replace this with dynamic value
  const paymentType = query.get('paymentType');// Replace this with dynamic value


  useEffect(() => {
    // Delay the API call by 2 seconds
    const timer = setTimeout(() => {
      // Make the API call
      fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/addpaymentinfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderId: orderId,
          amount: amount,
          paymentMethod:paymentMethod,
          paymentType:paymentType
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('API call successful:', data);
      })
      .catch(error => {
        console.error('Error in API call:', error);
      });
    }, 1000); 
    // Cleanup the timer if component unmounts
    return () => clearTimeout(timer);
  }, [orderId, amount, paymentType, paymentMethod]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full">
        <div className="text-center">
          {/* Centered Animated Checkmark Icon */}
          <div className="flex justify-center items-center mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 48 48">
              <linearGradient id="I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#21ad64"></stop>
                <stop offset="1" stopColor="#088242"></stop>
              </linearGradient>
              <path fill="url(#I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1)" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
              <path d="M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172C34.219,15.391,32.953,15.391,32.172,16.172z" opacity=".05"></path>
              <path d="M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13C22.475,33.646,21.525,33.646,20.939,33.061z" opacity=".07"></path>
              <path fill="#fff" d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13C22.317,33.098,21.683,33.098,21.293,32.707z"></path>
            </svg>
          </div>
          <h2 className="mt-4 text-2xl font-bold text-gray-900">Payment Successful</h2>
          <p className="mt-2 text-sm text-gray-500">Your payment has been processed successfully.</p>
        </div>
      </div>
    </div>
  );
}
