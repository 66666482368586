import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogDetail = () => {
    const { id } = useParams(); // Access the blog id from the URL
    const location = useLocation();
    const blog = location.state?.blog; // Get the passed blog data

    if (!blog) {
        return <div>No blog data found</div>;
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true, // Enable autoplay for better engagement
        autoplaySpeed: 3000, // Time between slides
        arrows: false // Remove arrows from the slider
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-10">
               
                <h1 className="text-3xl font-bold text-center mb-10">{blog.name}</h1>

                {/* Slider container */}
                <Slider {...settings}>
                    {blog.image.map((item, index) => (
                        <div key={index} className="px-3"> {/* Add horizontal padding to space slides */}
                            <img
                                src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${item}`}
                                className="w-full h-auto rounded-lg mx-auto"
                                alt={item.title}
                            />
                        </div>
                    ))}
                </Slider>

                {/* Blog Content */}
                <div className="text-gray-500 mb-2 mt-10 ">
                    {new Date(blog.date).toLocaleDateString()}
                </div>
                <div className="prose prose-lg max-w-none text-justify">
                    {ReactHtmlParser(blog.long_description)}
                </div>
                <p className="mt-4 text-sm text-gray-700">{blog.keyword}</p>
            </div>
        </div>
    );
};

export default BlogDetail;
