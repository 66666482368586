import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/layouts/header';
import Home from './components/layouts/home';
import { useLocation } from 'react-router-dom';
import './App.css';
import OneWayDetails from './components/pages/detailsPage/onewayDetails';
import RoundTripDetails from './components/pages/detailsPage/roundTripDetails';
import EightHoursDetails from './components/pages/detailsPage/hourlyRentDetails/eight_hours_details';
import TenHoursDetails from './components/pages/detailsPage/hourlyRentDetails/ten_Hours_details';
import AirportDetails from './components/pages/detailsPage/airportDetails';
import OneWay_car_details from './components/pages/Select_car_details/oneWay_car_details';
import TwelveHoursDetails from './components/pages/detailsPage/hourlyRentDetails/twelve_hours_details';
import Footer from './components/layouts/footer';
import Blog from './components/layouts/Blog';
import Testimonial from './components/layouts/testimonial_details';
import ContactUs from './components/layouts/contactUs';
import PrivacyPolicy from './components/layouts/privacy_Policy';
import DemoInvoice from './demo_invoice';
import RefundPolicy from './components/layouts/refund_policy';
import ShippingPolicy from './components/layouts/shipping_policy';
import Terms_conditions from './components/layouts/Terms & Conditions';
import AboutUs from './components/layouts/aboutus';
import BlogDetails from './components/layouts/BlogDetails';
import Route_page from './components/layouts/Routes';
import ScrollToTop from './ScrollToTop';
import Success from './components/layouts/success';
import Failed from './components/layouts/failed';


import SuratToAhmedabad from './components/Routes_pages/Surat/SuratToAhmedabad';
import SuratToAnand from './components/Routes_pages/Surat/SuratToAnand';
import SuratToAnkleshwar from './components/Routes_pages/Surat/SuratToAnkleshwar';
import SuratToBharuch from './components/Routes_pages/Surat/SuratToBharuch';
import SuratToDahej from './components/Routes_pages/Surat/SuratToDahej';
import SuratToSaputara from './components/Routes_pages/Surat/SuratToSaputara';
import SuratToAjmer from './components/Routes_pages/Surat/SuratToAjmer';
import SuratToBhiwandi from './components/Routes_pages/Surat/SuratToBhiwandi';
import SuratToHimmatnagar from './components/Routes_pages/Surat/SuratToHimmatnagar';
import SuratToMumbaiAirport from './components/Routes_pages/Surat/SuratToMumbaiAirport';
import SuratToUlhasnagar from './components/Routes_pages/Surat/SuratToUlhasnagar';
import SuratToBadlapur from './components/Routes_pages/Surat/SuratToBadlapur';
import SuratToDadar from './components/Routes_pages/Surat/SuratToDadar';
import SuratToPanvel from './components/Routes_pages/Surat/SuratToPanvel';
import SuratToMumbai from './components/Routes_pages/Surat/SuratToMumbai';
import SuratToKota from './components/Routes_pages/Surat/SuratToKota';
import SuratToAmreli from './components/Routes_pages/Surat/SuratToAmreli';
import SuratToNashik from './components/Routes_pages/Surat/SuratToNashik';
import SuratToNavimumbai from './components/Routes_pages/Surat/SuratToNavimumbai';
import SuratToPune from './components/Routes_pages/Surat/SuratToPune';
import SuratToRajkot from './components/Routes_pages/Surat/SuratToRajkot';
import SuratToLonavala from './components/Routes_pages/Surat/SuratToLonavala';
import SuratToThane from './components/Routes_pages/Surat/SuratToThane';
import SuratToVadodara from './components/Routes_pages/Surat/SuratToVadodara';
import SuratToJodhpur from './components/Routes_pages/Surat/SuratToJodhpur';
import SuratToIndore from './components/Routes_pages/Surat/SuratToIndore';
import SuratToPalitana from './components/Routes_pages/Surat/SuratToPalitana';
import SuratToJaipur from './components/Routes_pages/Surat/SuratToJaipur';
import SuratToJamnagar from './components/Routes_pages/Surat/SuratToJamnagar';
import SuratToBhuj from './components/Routes_pages/Surat/SuratToBhuj';
import SuratToBhavnagar from './components/Routes_pages/Surat/SuratToBhavnagar';
import SuratToDwarka from './components/Routes_pages/Surat/SuratToDwarka';
import SuratToDeolali from './components/Routes_pages/Surat/SuratToDeolali';
import SuratToVeraval from './components/Routes_pages/Surat/SuratToVeraval';
import SuratToVirar from './components/Routes_pages/Surat/SuratToVirar';
import SuratToValsad from './components/Routes_pages/Surat/SuratToValsad';
import SuratToGoa from './components/Routes_pages/Surat/SuratToGoa';
import SuratToUdaipur from './components/Routes_pages/Surat/SuratToUdaipur';
import SuratToDaman from './components/Routes_pages/Surat/SuratToDaman';
import SuratToNavsari from './components/Routes_pages/Surat/SuratToNavsari';
import SuratToVapi from './components/Routes_pages/Surat/SuratToVapi';
import SuratToBorivali from './components/Routes_pages/Surat/SuratToBorivali';
import SuratToKalyan from './components/Routes_pages/Surat/SuratToKalyan';
import SuratToVasai from './components/Routes_pages/Surat/SuratToVasai';
import SuratToSolapur from './components/Routes_pages/Surat/SuratToSolapur';
import SuratToBhopal from './components/Routes_pages/Surat/SuratToBhopal';
import SuratToUjjain from './components/Routes_pages/Surat/SuratToUjjain';
import SuratToBarmer from './components/Routes_pages/Surat/SuratToBarmer';
import SuratToNagpur from './components/Routes_pages/Surat/SuratToNagpur';
import SuratToMorbi from './components/Routes_pages/Surat/SuratToMorbi';
import SuratToDelhi from './components/Routes_pages/Surat/SuratToDelhi';
import SuratToPalanpur from './components/Routes_pages/Surat/SuratToPalanpur';
import SuratToBangalore from './components/Routes_pages/Surat/SuratToBangalore';
import SuratToMahabaleshwar from './components/Routes_pages/Surat/SuratToMahabaleshwar';
import SuratToStatueofunity from './components/Routes_pages/Surat/SuratToStatueofunity';
import SuratToDhari from './components/Routes_pages/Surat/SuratToDhari';
import SuratToShirdi from './components/Routes_pages/Surat/SuratToShirdi';
import SuratToPratapgarh from './components/Routes_pages/Surat/SuratToPratapgarh';
import SuratToPrayagraj from './components/Routes_pages/Surat/SuratToPrayagraj';
import SuratToKanpur from './components/Routes_pages/Surat/SuratToKanpur';
import SuratToJhansi from './components/Routes_pages/Surat/SuratToJhansi';
import SuratToSultanpur from './components/Routes_pages/Surat/SuratToSultanpur';
import SuratToVaranasi from './components/Routes_pages/Surat/SuratToVaranasi';
import SuratToAyodhya from './components/Routes_pages/Surat/SuratToAyodhya';

import SuratToAzamgarh from './components/Routes_pages/Surat/SuratToAzamgarh';
import SuratToRajula from './components/Routes_pages/Surat/SuratToRajula';
import SuratToVansda from './components/Routes_pages/Surat/SuratToVansda';
import SuratToRajpipla from './components/Routes_pages/Surat/SuratToRajpipla';
import SuratToVyara from './components/Routes_pages/Surat/SuratToVyara';
import SuratToKheda from './components/Routes_pages/Surat/SuratToKheda';
import SuratToDhule from './components/Routes_pages/Surat/SuratToDhule';
import SuratToBilimora from './components/Routes_pages/Surat/SuratToBilimora';
import SuratToPadra from './components/Routes_pages/Surat/SuratToPadra';
import SuratToPavagadh from './components/Routes_pages/Surat/SuratToPavagadh';
import SuratToChandod from './components/Routes_pages/Surat/SuratToChandod';
import SuratToMehsana from './components/Routes_pages/Surat/SuratToMehsana';
import SuratToPatan from './components/Routes_pages/Surat/SuratToPatan';
import SuratToVisnagar from './components/Routes_pages/Surat/SuratToVisnagar';
import SuratToKeshod from './components/Routes_pages/Surat/SuratToKeshod';
import SuratToIdar from './components/Routes_pages/Surat/SuratToIdar';
import SuratToAlibag from './components/Routes_pages/Surat/SuratToAlibag';
import SuratToKhopoli from './components/Routes_pages/Surat/SuratToKhopoli';
import SuratToNadiad from './components/Routes_pages/Surat/SuratToNadiad';
import SuratToBoisar from './components/Routes_pages/Surat/SuratToBoisar';
import SuratToBorsad from './components/Routes_pages/Surat/SuratToBorsad';
import SuratToKevadia from './components/Routes_pages/Surat/SuratToKevadia';
import SuratToGandevi from './components/Routes_pages/Surat/SuratToGandevi';
import SuratToSurendranagar from './components/Routes_pages/Surat/SuratToSurendranagar';
import SuratToUnjha from './components/Routes_pages/Surat/SuratToUnjha';
import SuratToUpleta from './components/Routes_pages/Surat/SuratToUpleta';
import SuratToRapar from './components/Routes_pages/Surat/SuratToRapar';
import SuratToKhambhat from './components/Routes_pages/Surat/SuratToKhambhat';
import SuratToJambusar from './components/Routes_pages/Surat/SuratToJambusar';
import SuratToDakor from './components/Routes_pages/Surat/SuratToDakor';
import SuratToTrimbak from './components/Routes_pages/Surat/SuratToTrimbak';
import SuratToNandurbar from './components/Routes_pages/Surat/SuratToNandurbar';
import SuratToSarangpur from './components/Routes_pages/Surat/SuratToSarangpur';
import SuratToPalghar from './components/Routes_pages/Surat/SuratToPalghar';
import SuratToUmargam from './components/Routes_pages/Surat/SuratToUmargam';
import SuratToChikhli from './components/Routes_pages/Surat/SuratToChikhli';
import SuratToAmbernath from './components/Routes_pages/Surat/SuratToAmbernath';
import SuratToUna from './components/Routes_pages/Surat/SuratToUna';
import SuratToSihor from './components/Routes_pages/Surat/SuratToSihor';
import SuratToPalej from './components/Routes_pages/Surat/SuratToPalej';
import SuratToPetlad from './components/Routes_pages/Surat/SuratToPetlad';
import SuratToAkola from './components/Routes_pages/Surat/SuratToAkola';
import SuratToHalvad from './components/Routes_pages/Surat/SuratToHalvad';
import SuratToBardoli from './components/Routes_pages/Surat/SuratToBardoli';
import SuratToHazira from './components/Routes_pages/Surat/SuratToHazira';
import SuratToDharampur from './components/Routes_pages/Surat/SuratToDharampur';
import SuratToAnjar from './components/Routes_pages/Surat/SuratToAnjar';
import SuratToJetpur from './components/Routes_pages/Surat/SuratToJetpur';
import SuratToAmravati from './components/Routes_pages/Surat/SuratToAmravati';
import SuratToHyderabad from './components/Routes_pages/Surat/SuratToHyderabad';
import SuratToWankaner from './components/Routes_pages/Surat/SuratToWankaner';
import SuratToSikar from './components/Routes_pages/Surat/SuratToSikar';





// ahemdabad
import AhmedabadToJodhpur from './components/Routes_pages/Ahmedabad/AhmedabadToJodhpur';
import AhmedabadToAnkleshwar from './components/Routes_pages/Ahmedabad/AhmedabadToAnkleshwar ';
import AhmedabadToBharuch from './components/Routes_pages/Ahmedabad/AhmedabadToBharuch';
import AhmedabadToDhrangadhra from './components/Routes_pages/Ahmedabad/AhmedabadToDhrangadhra';
import AhmedabadToDahej from './components/Routes_pages/Ahmedabad/AhmedabadToDahej';
import AhemdabadToDhari from './components/Routes_pages/Ahmedabad/AhemdabadToDhari';
import AhmedabadToGandhidham from './components/Routes_pages/Ahmedabad/AhmedabadToGandhidham';
import AhmedabadToHimatnagar from './components/Routes_pages/Ahmedabad/AhemdabadToHimatnagar';
import AhemdabadToJamnagar from './components/Routes_pages/Ahmedabad/AhemdabadToJamnagar';
import AhmedabadToBikaner from './components/Routes_pages/Ahmedabad/AhmedabadToBikaner';
import AhemdabadToMumbai from './components/Routes_pages/Ahmedabad/AhmedabadToMumbai';
import AhemdabadToMountAbu from './components/Routes_pages/Ahmedabad/AhemdabadToMountAbu';
import AhemdabadToRajkot from './components/Routes_pages/Ahmedabad/AhemdabadToRajkot';
import AhemdabadToSurat from './components/Routes_pages/Ahmedabad/AhemdabadToSurat';
import AhmedabadToSuratInnovaCab from './components/Routes_pages/Ahmedabad/AhmedabadToSuratInnovaCab';
import AhmedabadToMumbaiInnovaCab from './components/Routes_pages/Ahmedabad/AhmedabadToMumbaiInnovaCab';
import AhemdabadToUdaipur from './components/Routes_pages/Ahmedabad/AhemdabadToUdaipur';
import AhemdabadToVadodara from './components/Routes_pages/Ahmedabad/AhmedabadToVadodara';
import AhemdabadToBhavnagar from './components/Routes_pages/Ahmedabad/AhmedabadToBhavnagar';
import AhemdabadToBhuj from './components/Routes_pages/Ahmedabad/AhemdabadToBhuj';
import AhemdabadToNavsari from './components/Routes_pages/Ahmedabad/AhemdabadToNavasari';
import AhmedabadToPalitana from './components/Routes_pages/Ahmedabad/AhmedabadToPalitana';
import AhmedabadToDakor from './components/Routes_pages/Ahmedabad/AhemdabadToDakor';
import AhemdabadToMorbi from './components/Routes_pages/Ahmedabad/AhemdabadToMorbi';
import AhemdabadToGodhra from './components/Routes_pages/Ahmedabad/AhemdabadToGodhra';
import AhemdabadToUjjain from './components/Routes_pages/Ahmedabad/AhemdabadToUjjain';
import AhmedabadToIndore from './components/Routes_pages/Ahmedabad/AhemdabadToIndore';
import AhemdabadToAmreli from './components/Routes_pages/Ahmedabad/AhemdabadToAmreli';
import AhmedabadToVerval from './components/Routes_pages/Ahmedabad/AhmedabadToVeraval';
import AhemdabadToAbu from './components/Routes_pages/Ahmedabad/AhemdabadToAbuRoad';
import AhmedabadToDelhi from './components/Routes_pages/Ahmedabad/AhemdabadToDelhi';
import AhmedabadToBotad from './components/Routes_pages/Ahmedabad/AhemdabadToBotad';
import AhmedabadToChittorgarh from './components/Routes_pages/Ahmedabad/AhemdabadToChittorgarh';
import AhemdabadToRajula from './components/Routes_pages/Ahmedabad/AhemdabadToRajula';
import AhmedabadToDungarpur from './components/Routes_pages/Ahmedabad/AhemdabadToDungarpur';
import AhmedabadToGondal from './components/Routes_pages/Ahmedabad/AhemdabadToGondal';
import AhmedabadToJaipur from './components/Routes_pages/Ahmedabad/AhemdabadToJaipur';
import AhemdabadToJunagadh from './components/Routes_pages/Ahmedabad/AhemadabadToJunagadh';
import AhemdabadToPalanpur from './components/Routes_pages/Ahmedabad/AhemdabadToPalanpur';
import AhmedabadToModasa from './components/Routes_pages/Ahmedabad/AhemdabadToMoadasa';
import AhemdabadToGoa from './components/Routes_pages/Ahmedabad/AhemdabadToGoa';
import AhemdabadToAbuRoad from './components/Routes_pages/Ahmedabad/AhemdabadToAbuRoad';
import AhemdabadToNathdwara from './components/Routes_pages/Ahmedabad/AhemdabadToNathdwara';
import AhemdabadToPatan from './components/Routes_pages/Ahmedabad/AhemadabadToPatan';
import AhemdabadToPune from './components/Routes_pages/Ahmedabad/AhemdabadToPune';
import AhemdabadToRajpipla from './components/Routes_pages/Ahmedabad/AhemdabadToRajpipla';
import AhemdabadToSomnath from './components/Routes_pages/Ahmedabad/AhemdabadToSomnath';
import AhemdabadToSurendranagar from './components/Routes_pages/Ahmedabad/AhemdabadToSurendranagar';



// mumbai 

import MumbaiToAhemdabad from './components/Routes_pages/Mumbai/MumbaiToAhmedabad';
import MumbaiToAhmednagar from './components/Routes_pages/Mumbai/MumbaiToAhmednagar';
import MumbaiToAurangabad from './components/Routes_pages/Mumbai/MUmbaiToAurangabad';
import MumbaiToRajkot from './components/Routes_pages/Mumbai/MumbaiToRajkot';
import MumbaiToBharuch from './components/Routes_pages/Mumbai/MumbaiToBharuch';
import MumbaiToBikaner from './components/Routes_pages/Mumbai/MumbaiToBikaner';
import MumbaiToDahej from './components/Routes_pages/Mumbai/MumbaiToDahej';
import MumbaiToGoa from './components/Routes_pages/Mumbai/MUmbaiToGoa';
import MumbaiToDeolali from './components/Routes_pages/Mumbai/MumbaiToDeolali';
import MumbaiToPune from './components/Routes_pages/Mumbai/MumbaiToPune';
import MumbaiToSatara from './components/Routes_pages/Mumbai/MumbaiToSatara';
import MumbaiToShirdi from './components/Routes_pages/Mumbai/MumbaiToShiradi';
import MumbaiToNashik from './components/Routes_pages/Mumbai/MumbaiToNashik';
import MumbaiToSurat from './components/Routes_pages/Mumbai/MumbaiToSurat';
import MumbaiToTrimbakeshwar from './components/Routes_pages/Mumbai/MumbaiToTrimbakeshwar';
import MumbaiToVadodara from './components/Routes_pages/Mumbai/MumbaiToVadodara';
import MumbaiToVapi from './components/Routes_pages/Mumbai/MumbaiToVapi';
import MumbaiToNavsari from './components/Routes_pages/Mumbai/MumbaiToNavsari';
import MumbaiToLonavala from './components/Routes_pages/Mumbai/MumbaiToLonavala';
import MumbaiToKhandala from './components/Routes_pages/Mumbai/MumbaiToKhandala';
import MumbaiToBardoli from './components/Routes_pages/Mumbai/MumbaiToBardoli';
import MumbaiToValsad from './components/Routes_pages/Mumbai/MumbaiToValsad';
import MumbaiToKolahpur from './components/Routes_pages/Mumbai/MumbaiToKolahpur';
import MumbaiToPalghar from './components/Routes_pages/Mumbai/MumbaiToPalghar';
import MumbaiToPalanpur from './components/Routes_pages/Mumbai/MumbaiToPalanpur';
import MumbaiToMalegaon from './components/Routes_pages/Mumbai/MumbaiToMalegaon';
import MumbaiToJalgaon from './components/Routes_pages/Mumbai/MumbaiToJalgaon';
import MumbaiToBaramati from './components/Routes_pages/Mumbai/MumbaiToBaramati';
import MumbaiToAkola from './components/Routes_pages/Mumbai/MumbaiToAkola';
import MumbaiToLatur from './components/Routes_pages/Mumbai/MumbaiToLatur';
import MumbaiToDaman from './components/Routes_pages/Mumbai/MubmaiToDaman';
import MumbaiToOsmanabad from './components/Routes_pages/Mumbai/MumbaiToOsmanabad';
import MumbaiToJalna from './components/Routes_pages/Mumbai/MumbaiToJalna';
import MumbaiToNandurbar from './components/Routes_pages/Mumbai/MumbaiToNandurbar';
import MumbaiToDhule from './components/Routes_pages/Mumbai/MumbaiToDhule';
import MumbaiToKhopoli from './components/Routes_pages/Mumbai/MumbaiToKhopoli';
import MumbaiToShirpur from './components/Routes_pages/Mumbai/MumbaiToShirpur';
import MumbaiToAlibag from './components/Routes_pages/Mumbai/MumbaiToAlibag';
import MumbaiToAnkleshwar from './components/Routes_pages/Mumbai/MumbaiToAnkleshwar';
import MumbaiToVeraval from './components/Routes_pages/Mumbai/MumbaiToVeraval';
import MumbaiToVirar from './components/Routes_pages/Mumbai/MumbaiToVirar';
import MumbaiToMatheran from './components/Routes_pages/Mumbai/MumbaiToMatheran';
import MumbaiToIndore from './components/Routes_pages/Mumbai/MumbaiToIndore';
import MumbaiToNagpur from './components/Routes_pages/Mumbai/MumbaiToNagpur';
import MumbaiToSolapur from './components/Routes_pages/Mumbai/MumbaiToSolapur';
import MumbaiToSilvassa from './components/Routes_pages/Mumbai/MumaiToSilvassa';
import MumbaiToBhuj from './components/Routes_pages/Mumbai/MumbaiToBhuj';
import MumbaiToUdaipur from './components/Routes_pages/Mumbai/MumbaiToUdaipur';
import MumbaiToJamnagar from './components/Routes_pages/Mumbai/MUmbaiToJamnagar';
import MumbaiToBhavnagar from './components/Routes_pages/Mumbai/MumbaiToBhavnagar';
import MumbaiCentralToSurat from './components/Routes_pages/Mumbai/MumbaiCentralToSurat';
import MumbaiToSuratSedan from './components/Routes_pages/Mumbai/MumbaiToSuratSeden';
import MumbaiToSuratInnovaCrysta from './components/Routes_pages/Mumbai/MumbaiToSuratInnovaCrysta';
import MumbaiToSuratTempo from './components/Routes_pages/Mumbai/MumbaiToTempo';
import MumbaiToSuratInnova from './components/Routes_pages/Mumbai/MumbaiToSuratInnova';
import MumbaiToBadlapur from './components/Routes_pages/Mumbai/MumbaiToBadlapur';
import MumbaiToBhimashankar from './components/Routes_pages/Mumbai/MumbaiToBhimashankar';
import MumbaiToUmargam from './components/Routes_pages/Mumbai/MumbaiToUmargam';
import MumbaiToParbhani from './components/Routes_pages/Mumbai/MumbaiToParbhani';
import MumbaiToKopargaon from './components/Routes_pages/Mumbai/MumabiToKopargaon';
import MumbaiToMahad from './components/Routes_pages/Mumbai/MumbaiToMahad';
import MumbaiToJabalpur from './components/Routes_pages/Mumbai/MumbaiToJabalpur';
import MumbaiToAmbernath from './components/Routes_pages/Mumbai/MumbaiToAmbernath';
import MumbaiToPadgha from './components/Routes_pages/Mumbai/MumabiToPadgha';
import MumbaiToBhilad from './components/Routes_pages/Mumbai/MumbaiToBhilad';
import MumbaiToVikramgad from './components/Routes_pages/Mumbai/MumbaiToVikramgad';
import MumbaiToVajreshwari from './components/Routes_pages/Mumbai/MumbaiToVajreshwari';
import MumbaiToChandwad from './components/Routes_pages/Mumbai/MumbaiToChandwad';
import MumbaiToGandhinagar from './components/Routes_pages/Mumbai/MumbaiToGandhinagar';
import MumbaiToHazira from './components/Routes_pages/Mumbai/MumbaiToHazira';
import MumbaiToNadiad from './components/Routes_pages/Mumbai/MumbaiToNadiad';
import MumbaiToDharampur from './components/Routes_pages/Mumbai/MumbaiToDharampur';
import MumbaiToKarad from './components/Routes_pages/Mumbai/MumbaiToKarad';
import MumbaiToKarjat from './components/Routes_pages/Mumbai/MumbaiToKarjat';
import MumbaiToUlhasnagar from './components/Routes_pages/Mumbai/MumbaiToUlhasnagar';
import MumbaiToBoisar from './components/Routes_pages/Mumbai/MumbaiToBoisar';
import MumbaiToKheda from './components/Routes_pages/Mumbai/MumbaiToKheda';
import MumbaiToSaputara from './components/Routes_pages/Mumbai/MumbaiToSaputara';
import MumbaiToGandevi from './components/Routes_pages/Mumbai/MumabiToGandevi';
import MumbaiToBhusawal from './components/Routes_pages/Mumbai/MumbaiToBhusawal';
import MumbaiToJawhar from './components/Routes_pages/Mumbai/MumbaiToJawhar';
import MumbaiToTarapur from './components/Routes_pages/Mumbai/MumbaiToTarapur';
import MumbaiToMurbad from './components/Routes_pages/Mumbai/MumbaiToMurbad';
import MumbaiToAnjangaon from './components/Routes_pages/Mumbai/MumbaiTOAnjangaon';
import MumbaiToVyara from './components/Routes_pages/Mumbai/MumbaiToVyara';
import MumbaiToSangamner from './components/Routes_pages/Mumbai/MumbaiToSangamner';
import MumbaiToRatnagiri from './components/Routes_pages/Mumbai/MUmbaiToRatnagiri';
import MumbaiToSangli from './components/Routes_pages/Mumbai/MumbaiTOSangli';
import MumbaiToHyderabad from './components/Routes_pages/Mumbai/MumbaiToHyderabad';
import MumbaiToPardi from './components/Routes_pages/Mumbai/MumbaiToPardi';
import MumbaiToChakan from './components/Routes_pages/Mumbai/MumbaiToChakan';
import MumbaiToRasayani from './components/Routes_pages/Mumbai/MumbaiTORasayani';
import MumbaiToWada from './components/Routes_pages/Mumbai/MUmbaiToWada';
import MumbaiToKasara from './components/Routes_pages/Mumbai/MumbaiToKasara';
import MumbaiToShahapur from './components/Routes_pages/Mumbai/MUmbaiTOShahapur';
import MumbaiToNagothane from './components/Routes_pages/Mumbai/MumbaiToNagothane';
import MumbaiToSinnar from './components/Routes_pages/Mumbai/MumbaiToSinnar';
import MumbaiToBeed from './components/Routes_pages/Mumbai/MUmbaiTOBeed';
import MumbaiToIgatpuri from './components/Routes_pages/Mumbai/MumbaiToIgatpuri';
import MumbaiToBhopal from './components/Routes_pages/Mumbai/MumbaiToBhopal';
import MumbaiToDahanu from './components/Routes_pages/Mumbai/MumbaiToDahanu';
import MumbaiToBilimora from './components/Routes_pages/Mumbai/MumbaiToBilimora';
import MumbaiToManmad from './components/Routes_pages/Mumbai/MumbaiToManmad';
import MumbaiToBengaluru from './components/Routes_pages/Mumbai/MumbaiToBengaluru';
import MumbaiToChikhali from './components/Routes_pages/Mumbai/MumbaiToChikhali';
import MumbaiToManor from './components/Routes_pages/Mumbai/MumbaiToManor';
import MumbaiToLasalgaon from './components/Routes_pages/Mumbai/MubmaiToLasalgaon';
import MumbaiToSakri from './components/Routes_pages/Mumbai/MumbaiToSakri';
import MumbaiToUdvada from './components/Routes_pages/Mumbai/MumbaiToUdvada';


// Daman

import DamanToMumbai from './components/Routes_pages/Daman/DamanToMumbai';
import DamanToMehsana from './components/Routes_pages/Daman/DamanToMehsana';
import DamanToSurat from './components/Routes_pages/Daman/DamanToSurat';
import DamanToAnkleshwar from './components/Routes_pages/Daman/DamanToAnkleshwar';
import DamanToGoa from './components/Routes_pages/Daman/DamanToGoa';
import DamanToBardoli from './components/Routes_pages/Daman/DamanToBardoli';
import DamanToBharuch from './components/Routes_pages/Daman/DamanToBharuch';
import DamanToKalyan from './components/Routes_pages/Daman/DamanToKalyan';
import DamanToLonavala from './components/Routes_pages/Daman/DamanToLonavala';
import DamanToNashik from './components/Routes_pages/Daman/DamanToNashik';
import DamanToNavsari from './components/Routes_pages/Daman/DamanToNavsari';
import DamanToPune from './components/Routes_pages/Daman/DamanToPune';
import DamanToRajkot from './components/Routes_pages/Daman/DamanToRajkot';
import DamanToShirdi from './components/Routes_pages/Daman/DamanToShirdi';
import DamanToMumbaiAirport from './components/Routes_pages/Daman/DamanToMumbaiAirport';
import DamanToHazira from './components/Routes_pages/Daman/DamanToHazira';
import DamanToDahej from './components/Routes_pages/Daman/DamanToDahej';
import DamanToVasaiVirar from './components/Routes_pages/Daman/DamanToVasaiVirar';
import DamanToBhiwandi from './components/Routes_pages/Daman/DamanToBhiwandi';
import DamanToBadlapur from './components/Routes_pages/Daman/DamanToBadlapur';




//vapi

// vapi
import VapiToMumbai from './components/Routes_pages/Vapi/VapiToMumbai';
import VapiToMumbaiAirport from './components/Routes_pages/Vapi/VapiToMumbaiAirport';
import VapiToNaviMumbai from './components/Routes_pages/Vapi/VapiToNaviMumbai';
import VapiToDaman from './components/Routes_pages/Vapi/VapiToDaman';
import VapiToAhmedabad from './components/Routes_pages/Vapi/VapiToAhmedabad';
import VapiToNagpur from './components/Routes_pages/Vapi/VapiToNagpur';
import VapiToSolapur from './components/Routes_pages/Vapi/VapiToSolapur';
import VapiToSurat from './components/Routes_pages/Vapi/VapiToSurat';
import VapiToThane from './components/Routes_pages/Vapi/VapiToThane';
import VapiToNashik from './components/Routes_pages/Vapi/VapiToNashik';
import VapiToPune from './components/Routes_pages/Vapi/VapiToPune';
import VapiToShirdi from './components/Routes_pages/Vapi/VapiToShirdi';
import VapiToBhiwandi from './components/Routes_pages/Vapi/VapiToBhiwandi';
import VapiToBadlapur from './components/Routes_pages/Vapi/VapiToBadlapur';
import VapiToDadar from './components/Routes_pages/Vapi/VapiToDadar';
import VapiToPanvel from './components/Routes_pages/Vapi/VapiToPanvel';
import VapiToMumbaiCentral from './components/Routes_pages/Vapi/VapiToMumbaiCentral';
import VapiToRajkot from './components/Routes_pages/Vapi/VapiToRajkot';
import VapiToBhuj from './components/Routes_pages/Vapi/VapiToBhuj';
import VapiToJamnagar from './components/Routes_pages/Vapi/VapiToJamnagar';
import VapiToUdaipur from './components/Routes_pages/Vapi/VapiToUdaipur';
import VapiToIndore from './components/Routes_pages/Vapi/VapiToIndore';
import VapiToGoa from './components/Routes_pages/Vapi/VapiToGoa';
import VapiToLonavala from './components/Routes_pages/Vapi/VapiToLonavala';
import VapiToAnand from './components/Routes_pages/Vapi/VapiToAnand';
import VapiToBharuch from './components/Routes_pages/Vapi/VapiToBharuch';
import VapiToVadodara from './components/Routes_pages/Vapi/VapiToVadodara';


// valsad
import ValsadToAhemdabad from './components/Routes_pages/Valsad/ValsadToAhmedabad';
import ValsadToBhopal from './components/Routes_pages/Valsad/ValsadToBhopal';
import ValsadToDaman from './components/Routes_pages/Valsad/ValsadToAhmedabad';
import ValsadToGoa from './components/Routes_pages/Valsad/ValsadToGoa';
import ValsadToHazira from './components/Routes_pages/Valsad/ValsadToHazira';
import ValsadToIndore from './components/Routes_pages/Valsad/ValsadToIndore';
import ValsadToLonavala from './components/Routes_pages/Valsad/ValsadToLonavala';
import ValsadToMumbai from './components/Routes_pages/Valsad/ValsadToMumbai';
import ValsadToNashik from './components/Routes_pages/Valsad/ValsadToNashik';
import ValsadToNavsari from './components/Routes_pages/Valsad/ValsadToNavsari';
import ValsadToPune from './components/Routes_pages/Valsad/ValsadToPune';
import ValsadToShirdi from './components/Routes_pages/Valsad/ValsadToShirdi';
import ValsadToSilvassa from './components/Routes_pages/Valsad/ValsadToSilvassa';
import ValsadToSurat from './components/Routes_pages/Valsad/ValsadToSurat';
import ValsadToUjjain from './components/Routes_pages/Valsad/ValsadToUjjain';
import ValsadToVadodara from './components/Routes_pages/Valsad/ValsadToVadodara';
import ValsadToVapi from './components/Routes_pages/Valsad/ValsadToVapi';



// amreli
import AmreliToSurat from './components/Routes_pages/Amreli/AmreliToSurat';
import AmreliToBabra from './components/Routes_pages/Amreli/AmreliToBabra';
import AmreliToRajula from './components/Routes_pages/Amreli/AmreliToRajula';
import AmreliToRajkot from './components/Routes_pages/Amreli/AmreliToRajkot';
import AmreliToPune from './components/Routes_pages/Amreli/AmreliToPune';
import AmreliToAhmedabadAirport from './components/Routes_pages/Amreli/AmreliToAhmedabadAirport';
import AmreliToDhari from './components/Routes_pages/Amreli/AmreliToDhari';
import AmreliToJamnagar from './components/Routes_pages/Amreli/AmreliToJamnagar';
import AmreliToVadodara from './components/Routes_pages/Amreli/AmreliToVadodara';
import AmreliToNavsari from './components/Routes_pages/Amreli/AmreliToNavsari';
import AmreliToDiu from './components/Routes_pages/Amreli/AmreliToDiu';
import AmreliToChotila from './components/Routes_pages/Amreli/AmreliToChotila';
import AmreliToDwarka from './components/Routes_pages/Amreli/AmreliToDwarka';
import AmreliToGoa from './components/Routes_pages/Amreli/AmreliToGoa';
import AmreliToRamdevra from './components/Routes_pages/Amreli/AmreliToRamdevra';
import AmreliToVeraval from './components/Routes_pages/Amreli/AmreliToVeraval';
import AmreliToJunagadh from './components/Routes_pages/Amreli/AmreliToJunagadh';
import AmreliToAhmedabad from './components/Routes_pages/Amreli/AmreliToAhmedabad';
import AmreliToRajkotAirport from './components/Routes_pages/Amreli/AmreliToRajkotAirport';
import AmreliToBhavnagar from './components/Routes_pages/Amreli/AmreliToBhavnagar';
import AmreliToPalitana from './components/Routes_pages/Amreli/AmreliToPalitana';
import AmreliToSavarkundla from './components/Routes_pages/Amreli/AmreliToSavarkundla';
import AmreliToMumbai from './components/Routes_pages/Amreli/AmreliToMumbai';
import AmreliToAmbardiSafariPark from './components/Routes_pages/Amreli/AmreliToAmbardiSafariPark';
import AmreliToGirNationalPark from './components/Routes_pages/Amreli/AmreliToGirNationalPark';
import AmreliToSasanGir from './components/Routes_pages/Amreli/AmreliToSasanGir';


// silvassa


import SilvassaToMumbai from './components/Routes_pages/Silvassa/SilvassaToMumbai';
import SilvassaToGoa from './components/Routes_pages/Silvassa/SilvassaToGoa';
import SilvassaToAhmedabad from './components/Routes_pages/Silvassa/SilvassaToAhmedabad';
import SilvassaToLonavala from './components/Routes_pages/Silvassa/SilvassaToLonavala';
import SilvassaToNashik from './components/Routes_pages/Silvassa/SilvassaToNashik';
import SilvassaToPune from './components/Routes_pages/Silvassa/SilvassaToPune';
import SilvassaToKalyan from './components/Routes_pages/Silvassa/SilvassaToKalyan';
import SilvassaToThane from './components/Routes_pages/Silvassa/SilvassaToThane';
import SilvassaToRajkot from './components/Routes_pages/Silvassa/SilvassaToRajkot';
import SilvassaToUdaipur from './components/Routes_pages/Silvassa/SilvassaToUdaipur';
import SilvassaToBoisar from './components/Routes_pages/Silvassa/SilvassaToBoisar';
import SilvassaToVasaiVirar from './components/Routes_pages/Silvassa/SilvassaToVasaiVirar';
import SilvassaToSurat from './components/Routes_pages/Silvassa/SilvassaToSurat';
import SilvassaToVadodara from './components/Routes_pages/Silvassa/SilvassaToVadodara';




// bardoli

// Bardoli 
import BardoliToMumbai from './components/Routes_pages/Bardoli/BardoliToMumbai';
import BardoliToKalyan from './components/Routes_pages/Bardoli/BardoliToKalyan';
import BardoliToThane from './components/Routes_pages/Bardoli/BardoliToThane';
import BardoliToBhiwandi from './components/Routes_pages/Bardoli/BardoliToBhiwandi';
import BardoliToBadlapur from './components/Routes_pages/Bardoli/BardoliToBadlapur';
import BardoliToDadar from './components/Routes_pages/Bardoli/BardoliToDadar';
import BardoliToPanvel from './components/Routes_pages/Bardoli/BardoliToPanvel';
import BardoliToMumbaiCentral from './components/Routes_pages/Bardoli/BardoliToMumbaiCentral';
import BardoliToVasaiVirar from './components/Routes_pages/Bardoli/BardoliToVasaiVirar';
import BardoliToMumbaiAirport from './components/Routes_pages/Bardoli/BardoliToMumbaiAirport';
import BardoliToNaviMumbai from './components/Routes_pages/Bardoli/BardoliToNaviMumbai';
import BardoliToPune from './components/Routes_pages/Bardoli/BardoliToPune';
import BardoliToAhmedabadAirport from './components/Routes_pages/Bardoli/BardoliToAhmedabadAirport';
import BardoliToSuratAirport from './components/Routes_pages/Bardoli/BardoliToSuratAirport';
import BardoliToNashik from './components/Routes_pages/Bardoli/BardoliToNashik';
import BardoliToShirdi from './components/Routes_pages/Bardoli/BardoliToShirdi';
import BardoliToRajkot from './components/Routes_pages/Bardoli/BardoliToRajkot';
import BardoliToIndore from './components/Routes_pages/Bardoli/BardoliToIndore';
import BardoliToUdaipur from './components/Routes_pages/Bardoli/BardoliToUdaipur';
import BardoliToDaman from './components/Routes_pages/Bardoli/BardoliToDaman';
import BardoliToAnand from './components/Routes_pages/Bardoli/BardoliToAnand';
import BardoliToBharuch from './components/Routes_pages/Bardoli/BardoliToBharuch';
import BardoliToGoa from './components/Routes_pages/Bardoli/BardoliToGoa';
import BardoliToBhuj from './components/Routes_pages/Bardoli/BardoliToBhuj';
import BardoliToJamnagar from './components/Routes_pages/Bardoli/BardoliToJamnagar';
import BardoliToNavsari from './components/Routes_pages/Bardoli/BardoliToNavsari';
import BardoliToSilvassa from './components/Routes_pages/Bardoli/BardoliToSilvassa';
import BardoliToVadodara from './components/Routes_pages/Bardoli/BardoliToVadodara';
import BardoliToVapi from './components/Routes_pages/Bardoli/BardoliToVapi';


// bharuch 
import BharuchToSurat from './components/Routes_pages/Bharuch/BharuchToSurat';
import BharuchToAhmedabadAirport from './components/Routes_pages/Bharuch/BharuchToAhmedabadAirport';
import BharuchToMumbaiAirport from './components/Routes_pages/Bharuch/BharuchToMumbaiAirport';
import BharuchToPune from './components/Routes_pages/Bharuch/BharuchToPune';
import BharuchToDaman from './components/Routes_pages/Bharuch/BharuchToDaman';
import BharuchToVadodara from './components/Routes_pages/Bharuch/BharuchToVadodara';
import BharuchToUdaipur from './components/Routes_pages/Bharuch/BharuchToUdaipur';
import BharuchToVapi from './components/Routes_pages/Bharuch/BharuchToVapi';
import BharuchToSilvassa from './components/Routes_pages/Bharuch/BharuchToSilvassa';
import BharuchToKalyan from './components/Routes_pages/Bharuch/BharuchToKalyan';
import BharuchToBhiwandi from './components/Routes_pages/Bharuch/BharuchToBhiwandi';
import BharuchToNaviMumbai from './components/Routes_pages/Bharuch/BharuchToNaviMumbai';
import BharuchToAjmer from './components/Routes_pages/Bharuch/BharuchToAjmer';
import BharuchToRajkot from './components/Routes_pages/Bharuch/BharuchToRajkot';
import BharuchToIndore from './components/Routes_pages/Bharuch/BharuchToIndore';
import BharuchToGoa from './components/Routes_pages/Bharuch/BharuchToGoa';
import BharuchToBadlapur from './components/Routes_pages/Bharuch/BharuchToBadlapur';



function App() {
  return (
    <>
     <Router>
      <AppContent />
    </Router>
      
    </>
  );
}

const AppContent = () => {
  const location = useLocation();

  const noFooterPaths = ['/payment-success'];
  const isNoFooter = noFooterPaths.some(path => location.pathname.startsWith(path));
  const noHeaderFooterPaths = ['/payment-success'];

  return (
    <>
     {!noHeaderFooterPaths.includes(location.pathname) && <Header />}

        <ScrollToTop />
        <Routes>
          {/* surat */}
          <Route path='/surat-to-ahm' element={<SuratToAhmedabad />} />
          <Route path='/surat-to-anand' element={<SuratToAnand />} />
          <Route path='/surat-to-ankleshwar' element={<SuratToAnkleshwar />} />
          <Route path='/surat-to-bharuch' element={<SuratToBharuch />} />
          <Route path='/surat-to-dahej' element={<SuratToDahej />} />
          <Route path='/surat-to-saputara' element={<SuratToSaputara />} />
          <Route path='/surat-to-ajmer' element={<SuratToAjmer />} />
          <Route path='/surat-to-bhiwandi' element={<SuratToBhiwandi />} />
          <Route path='/surat-to-himmatnagar' element={<SuratToHimmatnagar />} />
          <Route path='/surat-to-mumbai' element={<SuratToMumbaiAirport />} />
          <Route path='/surat-to-ulhasnagar' element={<SuratToUlhasnagar />} />
          <Route path='/surat-to-badlapur' element={<SuratToBadlapur />} />
          <Route path='/surat-to-dadar' element={<SuratToDadar />} />
          <Route path='/surat-to-panvel' element={<SuratToPanvel />} />
          <Route path='/surat-to-mumbai' element={<SuratToMumbaiAirport />} />
          <Route path='/surat-to-kota' element={<SuratToKota />} />
          <Route path='/surat-to-amreli' element={<SuratToAmreli />} />
          <Route path='/surat-to-nashik' element={<SuratToNashik />} />
          <Route path='/surat-to-navimumbai' element={<SuratToNavimumbai />} />
          <Route path='/surat-to-pune' element={<SuratToPune />} />
          <Route path='/surat-to-rajkot' element={<SuratToRajkot />} />
          <Route path='/surat-to-lonavala' element={<SuratToLonavala />} />
          <Route path='/surat-to-thane' element={<SuratToThane />} />
          <Route path='/surat-to-vadodara' element={<SuratToVadodara />} />
          <Route path='/surat-to-jodhpur' element={<SuratToJodhpur />} />
          <Route path='/surat-to-indore' element={<SuratToIndore />} />
          <Route path='/surat-to-palitana' element={<SuratToPalitana />} />
          <Route path='/surat-to-jaipur' element={<SuratToJaipur />} />
          <Route path='/surat-to-jamnagar' element={<SuratToJamnagar />} />
          <Route path='/surat-to-bhuj' element={<SuratToBhuj />} />
          <Route path='/surat-to-bhavnagar' element={<SuratToBhavnagar />} />
          <Route path='/surat-to-dwarka' element={<SuratToDwarka />} />
          <Route path='/surat-to-deolali' element={<SuratToDeolali />} />
          <Route path='/surat-to-veraval' element={<SuratToVeraval />} />
          <Route path='/surat-to-virarMaharashtra' element={<SuratToVirar />} />
          <Route path='/surat-to-valsad' element={<SuratToValsad />} />
          <Route path='/surat-to-goa' element={<SuratToGoa />} />
          <Route path='/surat-to-udaipur' element={<SuratToUdaipur />} />
          <Route path='/surat-to-daman' element={<SuratToDaman />} />
          <Route path='/surat-to-navsari' element={<SuratToNavsari />} />
          <Route path='/surat-to-vapi' element={<SuratToVapi />} />
          <Route path='/surat-to-borivali' element={<SuratToBorivali />} />
          <Route path='/surat-to-kalyan' element={<SuratToKalyan />} />
          <Route path='/surat-to-vasai' element={<SuratToVasai />} />
          <Route path='/surat-to-solapur' element={<SuratToSolapur />} />
          <Route path='/surat-to-bhopal' element={<SuratToBhopal />} />
          <Route path='/surat-to-ujjain' element={<SuratToUjjain />} />
          <Route path='/surat-to-ajmer' element={<SuratToAjmer />} />
          <Route path='/surat-to-barmer' element={<SuratToBarmer />} />
          <Route path='/surat-to-nagpur' element={<SuratToNagpur />} />
          <Route path='/surat-to-morbi' element={<SuratToMorbi />} />
          <Route path='/surat-to-delhi' element={<SuratToDelhi />} />
          <Route path='/surat-to-palanpur' element={<SuratToPalanpur />} />
          <Route path='/surat-to-bangalore' element={<SuratToBangalore />} />
          <Route path='/surat-to-mahabaleshwar' element={<SuratToMahabaleshwar />} />
          <Route path='/surat-to-statueofunity' element={<SuratToStatueofunity />} />
          <Route path='/surat-to-dhari' element={<SuratToDhari />} />
          <Route path='/surat-to-shirdi' element={<SuratToShirdi />} />
          <Route path='/surat-to-pratapgarh' element={<SuratToPratapgarh />} />
          <Route path='/surat-to-prayagraj' element={<SuratToPrayagraj />} />
          <Route path='/surat-to-kanpur' element={<SuratToKanpur />} />
          <Route path='/surat-to-jhansi' element={<SuratToJhansi />} />
          <Route path='/surat-to-sultanpur' element={<SuratToSultanpur />} />
          <Route path='/surat-to-varanasi' element={<SuratToVaranasi />} />
          <Route path='/surat-to-ayodhya' element={<SuratToAyodhya />} />
          <Route path='/surat-to-azamgarh' element={<SuratToAzamgarh />} />
          <Route path='/surat-to-rajula' element={<SuratToRajula />} />
          <Route path='/surat-to-vasai' element={<SuratToVasai />} />
          <Route path='/surat-to-vansda' element={<SuratToVansda />} />
          <Route path='/surat-to-rajpipla' element={<SuratToRajpipla />} />
          <Route path='/surat-to-vyara' element={<SuratToVyara />} />
          <Route path='/surat-to-kheda' element={<SuratToKheda />} />
          <Route path='/surat-to-dhule' element={<SuratToDhule />} />
          <Route path='/surat-to-bilimora' element={<SuratToBilimora />} />
          <Route path='/surat-to-padra' element={<SuratToPadra />} />
          <Route path='/surat-to-pavagadh' element={<SuratToPavagadh />} />
          <Route path='/surat-to-chandod' element={<SuratToChandod />} />
          <Route path='/surat-to-mehsana' element={<SuratToMehsana />} />
          <Route path='/surat-to-patan' element={<SuratToPatan />} />
          <Route path='/surat-to-visnagar' element={<SuratToVisnagar />} />
          <Route path='/surat-to-keshod' element={<SuratToKeshod />} />
          <Route path='/surat-to-idar' element={<SuratToIdar />} />
          <Route path='/surat-to-anand' element={<SuratToAnand />} />
          <Route path='/surat-to-alibag' element={<SuratToAlibag />} />
          <Route path='/surat-to-khopoli' element={<SuratToKhopoli />} />
          <Route path='/surat-to-nadiad' element={<SuratToNadiad />} />
          <Route path='/surat-to-boisar' element={<SuratToBoisar />} />
          <Route path='/surat-to-borsad' element={<SuratToBorsad />} />
          <Route path='/surat-to-kevadia' element={<SuratToKevadia />} />
          <Route path='/surat-to-gandevi' element={<SuratToGandevi />} />
          <Route path='/surat-to-surendranagar' element={<SuratToSurendranagar />} />
          <Route path='/surat-to-unjha' element={<SuratToUnjha />} />
          <Route path='/surat-to-upleta' element={<SuratToUpleta />} />
          <Route path='/surat-to-rapar' element={<SuratToRapar />} />
          <Route path='/surat-to-khambhat' element={<SuratToKhambhat />} />
          <Route path='/surat-to-jambusar' element={<SuratToJambusar />} />
          <Route path='/surat-to-nagpur' element={<SuratToNagpur />} />
          <Route path='/surat-to-solapur' element={<SuratToSolapur />} />
          <Route path='/surat-to-morbi' element={<SuratToMorbi />} />
          <Route path='/surat-to-dakor' element={<SuratToDakor />} />
          <Route path='/surat-to-trimbak' element={<SuratToTrimbak />} />
          <Route path='/surat-to-nandurbar' element={<SuratToNandurbar />} />
          <Route path='/surat-to-sarangpur' element={<SuratToSarangpur />} />
          <Route path='/surat-to-palghar' element={<SuratToPalghar />} />
          <Route path='/surat-to-umargam' element={<SuratToUmargam />} />
          <Route path='/surat-to-chikhli' element={<SuratToChikhli />} />
          <Route path='/surat-to-ambernath' element={<SuratToAmbernath />} />
          <Route path='/surat-to-una' element={<SuratToUna />} />
          <Route path='/surat-to-sihor' element={<SuratToSihor />} />
          <Route path='/surat-to-palej' element={<SuratToPalej />} />
          <Route path='/surat-to-petlad' element={<SuratToPetlad />} />
          <Route path='/surat-to-akola' element={<SuratToAkola />} />
          <Route path='/surat-to-deolali' element={<SuratToDeolali />} />
          <Route path='/surat-to-valsad' element={<SuratToValsad />} />
          <Route path='/surat-to-halvad' element={<SuratToHalvad />} />
          <Route path='/surat-to-bardoli' element={<SuratToBardoli />} />
          <Route path='/surat-to-hazira' element={<SuratToHazira />} />
          <Route path='/surat-to-dharampur' element={<SuratToDharampur />} />
          <Route path='/surat-to-anjar' element={<SuratToAnjar />} />
          <Route path='/surat-to-jetpur' element={<SuratToJetpur />} />
          <Route path='/surat-to-amravati' element={<SuratToAmravati />} />
          <Route path='/surat-to-hyderabad' element={<SuratToHyderabad />} />
          <Route path='/surat-to-wankaner' element={<SuratToWankaner />} />
          <Route path='/surat-to-sikar' element={<SuratToSikar />} />






          {/* ahemdabad */}
          <Route path='/ahemdabad-to-jodhpur' element={<AhmedabadToJodhpur />} />
          <Route path='/ahemdabad-to-ankleshwar' element={<AhmedabadToAnkleshwar />} />
          <Route path='/ahemdabad-to-bharuch' element={<AhmedabadToBharuch />} />
          <Route path='/ahemdabad-to-dhrangadhra' element={<AhmedabadToDhrangadhra />} />
          <Route path='/ahemdabad-to-dahej' element={<AhmedabadToDahej />} />
          <Route path='/ahemdabad-to-dhari' element={<AhemdabadToDhari />} />
          <Route path='/ahemdabad-to-gandhidham' element={<AhmedabadToGandhidham />} />
          <Route path='/ahemdabad-to-himmatnagar' element={<AhmedabadToHimatnagar />} />
          <Route path='/ahemdabad-to-jamnagar' element={<AhemdabadToJamnagar />} />
          <Route path='/ahemdabad-to-bikaner' element={<AhmedabadToBikaner />} />
          <Route path='/ahemdabad-to-mumbai' element={<AhemdabadToMumbai />} />
          <Route path='/ahemdabad-to-Mount Abu' element={<AhemdabadToMountAbu />} />
          <Route path='/ahemdabad-to-rajkot' element={<AhemdabadToRajkot />} />
          <Route path='/ahemdabad-to-surat' element={<AhemdabadToSurat />} />
          <Route path='/ahemdabad-to-surat-to-innova' element={<AhmedabadToSuratInnovaCab />} />
          <Route path='/ahemdabad-to-mumbai-to-innova' element={<AhmedabadToMumbaiInnovaCab />} />
          <Route path='/ahemdabad-to-udaipur' element={<AhemdabadToUdaipur />} />
          <Route path='/ahemdabad-to-vadodara' element={<AhemdabadToVadodara />} />
          <Route path='/ahemdabad-to-bhavnagar' element={<AhemdabadToBhavnagar />} />
          <Route path='/ahemdabad-to-bhuj' element={<AhemdabadToBhuj />} />
          <Route path='/ahemdabad-to-navsari' element={<AhemdabadToNavsari />} />
          <Route path='/ahemdabad-to-palitana' element={<AhmedabadToPalitana />} />
          <Route path='/ahemdabad-to-dakor' element={<AhmedabadToDakor />} />
          <Route path='/ahemdabad-to-morbi' element={<AhemdabadToMorbi />} />
          <Route path='/ahemdabad-to-godhara' element={<AhemdabadToGodhra />} />
          <Route path='/ahemdabad-to-ujjain' element={<AhemdabadToUjjain />} />
          <Route path='/ahemdabad-to-indore' element={<AhmedabadToIndore />} />
          <Route path='/ahemdabad-to-amreli' element={<AhemdabadToAmreli />} />
          <Route path='/ahemdabad-to-veraval' element={<AhmedabadToVerval />} />
          <Route path='/ahemdabad-to-mountabu' element={<AhemdabadToAbu />} />
          <Route path='/ahemdabad-to-delhi' element={<AhmedabadToDelhi />} />
          <Route path='/ahemdabad-to-botad' element={<AhmedabadToBotad />} />
          <Route path='/ahemdabad-to-chittorgarh' element={<AhmedabadToChittorgarh />} />
          <Route path='/ahemdabad-to-rajula' element={<AhemdabadToRajula />} />
          <Route path='/ahemdabad-to-dungarpur' element={<AhmedabadToDungarpur />} />
          <Route path='/ahemdabad-to-gondal' element={<AhmedabadToGondal />} />
          <Route path='/ahemdabad-to-jaipur' element={<AhmedabadToJaipur />} />
          <Route path='/ahemdabad-to-junagadh' element={<AhemdabadToJunagadh />} />
          <Route path='/ahemdabad-to-palanpur' element={<AhemdabadToPalanpur />} />
          <Route path='/ahemdabad-to-modasa' element={<AhmedabadToModasa />} />
          <Route path='/ahemdabad-to-goa' element={<AhemdabadToGoa />} />
          <Route path='/ahemdabad-to-aburoad' element={<AhemdabadToAbuRoad />} />
          <Route path='/ahemdabad-to-nathdwara' element={<AhemdabadToNathdwara />} />
          <Route path='/ahemdabad-to-patan' element={<AhemdabadToPatan />} />
          <Route path='/ahemdabad-to-pune' element={<AhemdabadToPune />} />
          <Route path='/ahemdabad-to-rajpipla' element={<AhemdabadToRajpipla />} />
          <Route path='/ahemdabad-to-somnath' element={<AhemdabadToSomnath />} />
          <Route path='/ahemdabad-to-surendranagar' element={<AhemdabadToSurendranagar />} />




{/* Daman */}

          <Route path='/daman-to-mumbai' element={<DamanToMumbai />} />
          <Route path='/daman-to-mehsana' element={<DamanToMehsana />} />
          <Route path='/daman-to-surat' element={<DamanToSurat />} />
          <Route path='/daman-to-ankleshwar' element={<DamanToAnkleshwar />} />
          <Route path='/daman-to-goa' element={<DamanToGoa />} />
          <Route path='/daman-to-bardoli' element={<DamanToBardoli />} />
          <Route path='/daman-to-bharuch' element={<DamanToBharuch />} />
          <Route path='/daman-to-kalyan' element={<DamanToKalyan />} />
          <Route path='/daman-to-lonavala' element={<DamanToLonavala />} />
          <Route path='/daman-to-nashik' element={<DamanToNashik />} />
          <Route path='/daman-to-navsari' element={<DamanToNavsari />} />
          <Route path='/daman-to-pune' element={<DamanToPune />} />
          <Route path='/daman-to-rajkot' element={<DamanToRajkot />} />
          <Route path='/daman-to-shirdi' element={<DamanToShirdi />} />
          <Route path='/daman-to-MumbaiAirport' element={<DamanToMumbaiAirport />} />
          <Route path='/daman-to-hazira' element={<DamanToHazira />} />
          <Route path='/daman-to-dahej' element={<DamanToDahej />} />
          <Route path='/daman-to-vasai-virar' element={<DamanToVasaiVirar />} />
          <Route path='/daman-to-bhiwandi' element={<DamanToBhiwandi />} />
          <Route path='/daman-to-badlapur' element={<DamanToBadlapur />} />
          







       {/* mumbai */}
         <Route path='/mumbai-to-ahemdabad' element={<MumbaiToAhemdabad />} />
         <Route path='/mumbai-to-ahmednagar' element={<MumbaiToAhmednagar />} />
         <Route path='/mumbai-to-aurangabad' element={<MumbaiToAurangabad />} />
         <Route path='/mumbai-to-rajkot' element={<MumbaiToRajkot />} />
         <Route path='/mumbai-to-bharuch' element={<MumbaiToBharuch />} />
         <Route path='/mumbai-to-bikaner' element={<MumbaiToBikaner />} />
         <Route path='/mumbai-to-dahej' element={<MumbaiToDahej />} />
         <Route path='/mumbai-to-deolali' element={<MumbaiToDeolali />} />
         <Route path='/mumbai-to-goa' element={<MumbaiToGoa />} />
         <Route path='/mumbai-to-pune' element={<MumbaiToPune />} />
         <Route path='/mumbai-to-satara' element={<MumbaiToSatara />} />
         <Route path='/mumbai-to-shirdi' element={<MumbaiToShirdi />} />
         <Route path='/mumbai-to-nashik' element={<MumbaiToNashik />} />
         <Route path='/mumbai-to-surat' element={<MumbaiToSurat />} />
         <Route path='/mumbai-to-trimbakeshwar' element={<MumbaiToTrimbakeshwar />} />
         <Route path='/mumbai-to-vadodara' element={<MumbaiToVadodara />} />
         <Route path='/mumbai-to-vapi' element={<MumbaiToVapi />} />
         <Route path='/mumbai-to-navsari' element={<MumbaiToNavsari />} />
         <Route path='/mumbai-to-lonavala' element={<MumbaiToLonavala />} />
         <Route path='/mumbai-to-khandala' element={<MumbaiToKhandala />} />
         <Route path='/mumbai-to-bardoli' element={<MumbaiToBardoli />} />
         <Route path='/mumbai-to-valsad' element={<MumbaiToValsad />} />
         <Route path='/mumbai-to-kolahpur' element={<MumbaiToKolahpur />} />
         <Route path='/mumbai-to-palghar' element={<MumbaiToPalghar />} />
         <Route path='/mumbai-to-palanpur' element={<MumbaiToPalanpur />} />
         <Route path='/mumbai-to-malegaon' element={<MumbaiToMalegaon />} />
         <Route path='/mumbai-to-jalgaon' element={<MumbaiToJalgaon />} />
         <Route path='/mumbai-to-baramati' element={<MumbaiToBaramati />} />
         <Route path='/mumbai-to-akola' element={<MumbaiToAkola />} />
         <Route path='/mumbai-to-latur' element={<MumbaiToLatur />} />
         <Route path='/mumbai-to-daman' element={<MumbaiToDaman />} />
         <Route path='/mumbai-to-osmanabad' element={<MumbaiToOsmanabad />} />
         <Route path='/mumbai-to-jalna' element={<MumbaiToJalna />} />
         <Route path='/mumbai-to-nandurbar' element={<MumbaiToNandurbar />} />
         <Route path='/mumbai-to-dhule' element={<MumbaiToDhule />} />
         <Route path='/mumbai-to-khopoli' element={<MumbaiToKhopoli />} />
         <Route path='/mumbai-to-shirpur' element={<MumbaiToShirpur />} />
         <Route path='/mumbai-to-alibag' element={<MumbaiToAlibag />} />
         <Route path='/mumbai-to-ankleshwar' element={<MumbaiToAnkleshwar />} />
         <Route path='/mumbai-to-veraval' element={<MumbaiToVeraval />} />
         <Route path='/mumbai-to-virar' element={<MumbaiToVirar/>} />
         <Route path='/mumbai-to-matheran' element={<MumbaiToMatheran/>} />
         <Route path='/mumbai-to-indore' element={<MumbaiToIndore/>} />
         <Route path='/mumbai-to-nagpur' element={<MumbaiToNagpur/>} />
         <Route path='/mumbai-to-solapur' element={<MumbaiToSolapur/>} />
         <Route path='/mumbai-to-silvassa' element={<MumbaiToSilvassa/>} />
         <Route path='/mumbai-to-bhuj' element={<MumbaiToBhuj/>} />
         <Route path='/mumbai-to-udaipur' element={<MumbaiToUdaipur/>} />
         <Route path='/mumbai-to-jamnagar' element={<MumbaiToJamnagar/>} />
         <Route path='/mumbai-to-bhavnagar' element={<MumbaiToBhavnagar/>} />
         <Route path='/mumbaicentral-to-surat' element={<MumbaiCentralToSurat/>} />
         <Route path='/mumbai-to-surat-sedan' element={<MumbaiToSuratSedan/>} />
         <Route path='/mumbai-to-surat-innovacrysta' element={<MumbaiToSuratInnovaCrysta/>} />
         <Route path='/mumbai-to-surat-tempo' element={<MumbaiToSuratTempo/>} />
         <Route path='/mumbai-to-surat-innova' element={<MumbaiToSuratInnova/>} />
         <Route path='/mumbai-to-satara' element={<MumbaiToSatara/>} />
         <Route path='/mumbai-to-badalpur' element={<MumbaiToBadlapur/>} />
         <Route path='/mumbai-to-bhimashankar' element={<MumbaiToBhimashankar/>} />
         <Route path='/mumbai-to-umargam' element={<MumbaiToUmargam/>} />
         <Route path='/mumbai-to-parbhani' element={<MumbaiToParbhani/>} />
         <Route path='/mumbai-to-kopargaon' element={<MumbaiToKopargaon/>} />
         <Route path='/mumbai-to-mahad' element={<MumbaiToMahad/>} />
         <Route path='/mumbai-to-jabalpur' element={<MumbaiToJabalpur/>} />
         <Route path='/mumbai-to-ambernath' element={<MumbaiToAmbernath/>} />
         <Route path='/mumbai-to-padgha' element={<MumbaiToPadgha/>} />
         <Route path='/mumbai-to-padgha' element={<MumbaiToPadgha/>} />
         <Route path='/mumbai-to-bhilad' element={<MumbaiToBhilad/>} />
         <Route path='/mumbai-to-vikramgad' element={<MumbaiToVikramgad/>} />
         <Route path='/mumbai-to-vajreshwari' element={<MumbaiToVajreshwari/>} />
         <Route path='/mumbai-to-chandwad' element={<MumbaiToChandwad/>} />
         <Route path='/mumbai-to-gandhinagar' element={<MumbaiToGandhinagar/>} />
         <Route path='/mumbai-to-hazira' element={<MumbaiToHazira/>} />
         <Route path='/mumbai-to-nadiad' element={<MumbaiToNadiad/>} />
         <Route path='/mumbai-to-dharampur' element={<MumbaiToDharampur/>} />
         <Route path='/mumbai-to-karjat' element={<MumbaiToKarjat/>} />
         <Route path='/mumbai-to-ulhasnagar' element={<MumbaiToUlhasnagar/>} />
         <Route path='/mumbai-to-boisar' element={<MumbaiToBoisar/>} />
         <Route path='/mumbai-to-kheda' element={<MumbaiToKheda/>} />
         <Route path='/mumbai-to-saputara' element={<MumbaiToSaputara/>} />
         <Route path='/mumbai-to-gandevi' element={<MumbaiToGandevi/>} />
         <Route path='/mumbai-to-bhusawal' element={<MumbaiToBhusawal/>} />
         <Route path='/mumbai-to-jawhar' element={<MumbaiToJawhar/>} />
         <Route path='/mumbai-to-tarapur' element={<MumbaiToTarapur/>} />

         <Route path='/mumbai-to-murbad' element={<MumbaiToMurbad/>} />
         <Route path='/mumbai-to-anjangaon' element={<MumbaiToAnjangaon/>} />
         <Route path='/mumbai-to-jalna' element={<MumbaiToJalna/>} />
         <Route path='/mumbai-to-vyara' element={<MumbaiToVyara/>} />
         <Route path='/mumbai-to-sangamner' element={<MumbaiToSangamner/>} />
         <Route path='/mumbai-to-ratnagiri' element={<MumbaiToRatnagiri/>} />
         <Route path='/mumbai-to-sangali' element={<MumbaiToSangli/>} />
         <Route path='/mumbai-to-hyderabad' element={<MumbaiToHyderabad/>} />
         <Route path='/mumbai-to-pardi' element={<MumbaiToPardi/>} />
         <Route path='/mumbai-to-chakan' element={<MumbaiToChakan/>} />
         <Route path='/mumbai-to-rasayani' element={<MumbaiToRasayani/>} />
         <Route path='/mumbai-to-wada' element={<MumbaiToWada/>} />
         <Route path='/mumbai-to-kasara' element={<MumbaiToKasara/>} />
         <Route path='/mumbai-to-shahapur' element={<MumbaiToShahapur/>} />
         <Route path='/mumbai-to-nagpur' element={<MumbaiToNagpur/>} />
         <Route path='/mumbai-to-nandurbar' element={<MumbaiToNandurbar/>} />
         <Route path='/mumbai-to-nagothane' element={<MumbaiToNagothane/>} />
         <Route path='/mumbai-to-sinnar' element={<MumbaiToSinnar/>} />
         <Route path='/mumbai-to-beed' element={<MumbaiToBeed/>} />
         <Route path='/mumbai-to-igatpuri' element={<MumbaiToIgatpuri/>} />
         <Route path='/mumbai-to-bhopal' element={<MumbaiToBhopal/>} />
         <Route path='/mumbai-to-dahanu' element={<MumbaiToDahanu/>} />
         <Route path='/mumbai-to-bilimora' element={<MumbaiToBilimora/>} />
         <Route path='/mumbai-to-manmad' element={<MumbaiToManmad/>} />
         <Route path='/mumbai-to-bengaluru' element={<MumbaiToBengaluru/>} />
         <Route path='/mumbai-to-chikhali' element={<MumbaiToChikhali/>} />
         <Route path='/mumbai-to-manor' element={<MumbaiToManor/>} />
         <Route path='/mumbai-to-lasalgaon' element={<MumbaiToLasalgaon/>} />
         <Route path='/mumbai-to-sakri' element={<MumbaiToSakri/>} />
         <Route path='/mumbai-to-udvada' element={<MumbaiToUdvada/>} />


         {/* vapi */}
          <Route path='/vapi-to-mumbai' element={<VapiToMumbai />} />
          <Route path='/vapi-to-mumbaiairport' element={<VapiToMumbaiAirport />} />
          <Route path='/vapi-to-navimumbai' element={<VapiToNaviMumbai />} />
          <Route path='/vapi-to-daman' element={<VapiToDaman />} />
          <Route path='/vapi-to-ahmedabad' element={<VapiToAhmedabad />} />
          <Route path='/vapi-to-nagpur' element={<VapiToNagpur />} />
          <Route path='/vapi-to-solapur' element={<VapiToSolapur />} />
          <Route path='/vapi-to-surat' element={<VapiToSurat />} />
          <Route path='/vapi-to-thane' element={<VapiToThane />} />
          <Route path='/vapi-to-nashik' element={<VapiToNashik />} />
          <Route path='/vapi-to-pune' element={<VapiToPune />} />
          <Route path='/vapi-to-shirdi' element={<VapiToShirdi />} />
          <Route path='/vapi-to-bhiwandi' element={<VapiToBhiwandi />} />
          <Route path='/vapi-to-badlapur' element={<VapiToBadlapur />} />
          <Route path='/vapi-to-dadar' element={<VapiToDadar />} />
          <Route path='/vapi-to-panvel' element={<VapiToPanvel />} />
          <Route path='/vapi-to-mumbaicentral' element={<VapiToMumbaiCentral />} />
          <Route path='/vapi-to-rajkot' element={<VapiToRajkot />} />
          <Route path='/vapi-to-bhuj' element={<VapiToBhuj />} />
          <Route path='/vapi-to-jamnagar' element={<VapiToJamnagar />} />
          <Route path='/vapi-to-udaipur' element={<VapiToUdaipur />} />
          <Route path='/vapi-to-indore' element={<VapiToIndore />} />
          <Route path='/vapi-to-goa' element={<VapiToGoa />} />
          <Route path='/vapi-to-lonavala' element={<VapiToLonavala />} />
          <Route path='/vapi-to-anand' element={<VapiToAnand />} />
          <Route path='/vapi-to-bharuch' element={<VapiToBharuch />} />
          <Route path='/vapi-to-vadodara' element={<VapiToVadodara />} />


{/* valsad */}
<Route path='/valsad-to-ahemdabd' element={<ValsadToAhemdabad/>} />
<Route path='/valsad-to-bhopal' element={<ValsadToBhopal/>} />
<Route path='/valsad-to-daman' element={<ValsadToDaman/>} />
<Route path='/valsad-to-goa' element={<ValsadToGoa/>} />
<Route path='/valsad-to-hazira' element={<ValsadToHazira/>} />
<Route path='/valsad-to-indore' element={<ValsadToIndore/>} />
<Route path='/valsad-to-lonavala' element={<ValsadToLonavala/>} />
<Route path='/valsad-to-mumbai' element={<ValsadToMumbai/>} />
<Route path='/valsad-to-navsari' element={<ValsadToNavsari/>} />
<Route path='/valsad-to-nashik' element={<ValsadToNashik/>} />
<Route path='/valsad-to-pune' element={<ValsadToPune/>} />
<Route path='/valsad-to-shirdi' element={<ValsadToShirdi/>} />
<Route path='/valsad-to-silvassa' element={<ValsadToSilvassa/>} />
<Route path='/valsad-to-surat' element={<ValsadToSurat/>} />
<Route path='/valsad-to-ujjain' element={<ValsadToUjjain/>} />
<Route path='/valsad-to-vadodara' element={<ValsadToVadodara/>} />
<Route path='/valsad-to-vapi' element={<ValsadToVapi/>} />




{/* amreli */}
          <Route path='/amreli-to-surat' element={<AmreliToSurat />} />
          <Route path='/amreli-to-babra' element={<AmreliToBabra />} />
          <Route path='/amreli-to-rajula' element={<AmreliToRajula />} />
          <Route path='/amreli-to-rajkot' element={<AmreliToRajkot />} />
          <Route path='/amreli-to-pune' element={<AmreliToPune />} />
          <Route path='/amreli-to-ahmedabadairport' element={<AmreliToAhmedabadAirport />} />
          <Route path='/amreli-to-dhari' element={<AmreliToDhari />} />
          <Route path='/amreli-to-jamnagar' element={<AmreliToJamnagar />} />
          <Route path='/amreli-to-vadodara' element={<AmreliToVadodara />} />
          <Route path='/amreli-to-navsari' element={<AmreliToNavsari />} />
          <Route path='/amreli-to-diu' element={<AmreliToDiu />} />
          <Route path='/amreli-to-chotila' element={<AmreliToChotila />} />
          <Route path='/amreli-to-dwarka' element={<AmreliToDwarka />} />
          <Route path='/amreli-to-goa' element={<AmreliToGoa />} />
          <Route path='/amreli-to-ramdevra' element={<AmreliToRamdevra />} />
          <Route path='/amreli-to-veraval' element={<AmreliToVeraval />} />
          <Route path='/amreli-to-junagadh' element={<AmreliToJunagadh />} />
          <Route path='/amreli-to-ahemdabad' element={<AmreliToAhmedabad />} />
          <Route path='/amreli-to-rajkotairport' element={<AmreliToRajkotAirport />} />
          <Route path='/amreli-to-bhavnagar' element={<AmreliToBhavnagar />} />
          <Route path='/amreli-to-palitana' element={<AmreliToPalitana />} />
          <Route path='/amreli-to-savarkundla' element={<AmreliToSavarkundla />} />
          <Route path='/amreli-to-mumbai' element={<AmreliToMumbai />} />
          <Route path='/amreli-to-ambardisafaripark' element={<AmreliToAmbardiSafariPark />} />
          <Route path='/amreli-to-girnationalpark' element={<AmreliToGirNationalPark />} />
          <Route path='/amreli-to-sasangir' element={<AmreliToSasanGir />} />



{/* silvassa */}
          <Route path='/silvassa-to-mumbai' element={<SilvassaToMumbai />} />
          <Route path='/silvassa-to-goa' element={<SilvassaToGoa />} />
          <Route path='/silvassa-to-ahmedabad' element={<SilvassaToAhmedabad />} />
          <Route path='/silvassa-to-lonavala' element={<SilvassaToLonavala />} />
          <Route path='/silvassa-to-nashik' element={<SilvassaToNashik />} />
          <Route path='/silvassa-to-pune' element={<SilvassaToPune />} />
          <Route path='/silvassa-to-kalyan' element={<SilvassaToKalyan/>} />
          <Route path='/silvassa-to-thane' element={<SilvassaToThane />} />
          <Route path='/silvassa-to-rajkot' element={<SilvassaToRajkot />} />
          <Route path='/silvassa-to-udaipur' element={<SilvassaToUdaipur />} />
          <Route path='/silvassa-to-boisar' element={<SilvassaToBoisar />} />
          <Route path='/silvassa-to-VasaiVirar' element={<SilvassaToVasaiVirar />} />
          <Route path='/silvassa-to-surat' element={<SilvassaToSurat />} />
          <Route path='/silvassa-to-vadodara' element={<SilvassaToVadodara />} />



{/* bardoli */}

<Route path='/bardoli-to-mumbai' element={<BardoliToMumbai />} />
        <Route path='/bardoli-to-kalyan' element={<BardoliToKalyan />} />
        <Route path='/bardoli-to-thane' element={<BardoliToThane />} />
        <Route path='/bardoli-to-bhiwandi' element={<BardoliToBhiwandi />} />
        <Route path='/bardoli-to-badlapur' element={<BardoliToBadlapur />} /> 
        <Route path='/bardoli-to-dadar' element={<BardoliToDadar />} /> 
        <Route path='/bardoli-to-panvel' element={<BardoliToPanvel />} /> 
        <Route path='/bardoli-to-mumbaicentral' element={<BardoliToMumbaiCentral />} /> 
        <Route path='/bardoli-to-vasaivirar' element={<BardoliToVasaiVirar />} /> 
        <Route path='/bardoli-to-mumbaiairport' element={<BardoliToMumbaiAirport />} /> 
        <Route path='/bardoli-to-navimumbai' element={<BardoliToNaviMumbai />} /> 
        <Route path='/bardoli-to-pune' element={<BardoliToPune />} /> 
        <Route path='/bardoli-to-ahmedabadairport' element={<BardoliToAhmedabadAirport />} /> 
        <Route path='/bardoli-to-suratairport' element={<BardoliToSuratAirport />} /> 
        <Route path='/bardoli-to-nashik' element={<BardoliToNashik />} /> 
        <Route path='/bardoli-to-shirdi' element={<BardoliToShirdi />} /> 
        <Route path='/bardoli-to-rajkot' element={<BardoliToRajkot />} /> 
        <Route path='/bardoli-to-indore' element={<BardoliToIndore />} /> 
        <Route path='/bardoli-to-udaipur' element={<BardoliToUdaipur />} /> 
        <Route path='/bardoli-to-daman' element={<BardoliToDaman />} /> 
        <Route path='/bardoli-to-bharuch' element={<BardoliToBharuch />} /> 
        <Route path='/bardoli-to-anand' element={<BardoliToAnand />} /> 
        <Route path='/bardoli-to-bhuj' element={<BardoliToBhuj />} /> 
        <Route path='/bardoli-to-goa' element={<BardoliToGoa />} />
        <Route path='/bardoli-to-jamnagar' element={<BardoliToJamnagar />} /> 
        <Route path='/bardoli-to-navsari' element={<BardoliToNavsari />} /> 
        <Route path='/bardoli-to-silvassa' element={<BardoliToSilvassa />} /> 
        <Route path='/bardoli-to-vadodara' element={<BardoliToVadodara />} /> 
        <Route path='/bardoli-to-vapi' element={<BardoliToVapi />} /> 



{/* bharuch */}
{/* {/ bharuch  /} */}
        <Route path='/bharuch-to-surat' element={<BharuchToSurat />} />
        <Route path='/bharuch-to-ahmairport' element={<BharuchToAhmedabadAirport />} />
        <Route path='/bharuch-to-mumbaiairport' element={<BharuchToMumbaiAirport />} />
        <Route path='/bharuch-to-pune' element={<BharuchToPune />} />
        <Route path='/bharuch-to-daman' element={<BharuchToDaman />} />
        <Route path='/bharuch-to-vadodara' element={<BharuchToVadodara />} />
        <Route path='/bharuch-to-udaipur' element={<BharuchToUdaipur />} />
        <Route path='/bharuch-to-vapi' element={<BharuchToVapi   />} />
        <Route path='/bharuch-to-silvassa' element={<BharuchToSilvassa   />} />
        <Route path='/bharuch-to-kalyan' element={<BharuchToKalyan   />} />
        <Route path='/bharuch-to-bhiwandi' element={<BharuchToBhiwandi   />} />
        <Route path='/bharuch-to-navimumbai' element={<BharuchToNaviMumbai   />} />
        <Route path='/bharuch-to-badlapur' element={<BharuchToBadlapur   />} />
        <Route path='/bharuch-to-ajmer' element={<BharuchToAjmer   />} />
        <Route path='/bharuch-to-pune' element={<BharuchToPune />} />
        <Route path='/bharuch-to-goa' element={<BharuchToGoa />} />
        <Route path='/bharuch-to-rajkot' element={<BharuchToRajkot />} />
        <Route path='/bharuch-to-indore' element={<BharuchToIndore />} />


        {/* // bharuch */}

<Route path='/bharuch-to-surat' element={<BharuchToSurat />} />
<Route path='/bharuch-to-ahmairport' element={<BharuchToAhmedabadAirport />} />
<Route path='/bharuch-to-mumbaiairport' element={<BharuchToMumbaiAirport />} />
<Route path='/bharuch-to-pune' element={<BharuchToPune />} />
<Route path='/bharuch-to-daman' element={<BharuchToDaman />} />
<Route path='/bharuch-to-vadodara' element={<BharuchToVadodara />} />
<Route path='/bharuch-to-udaipur' element={<BharuchToUdaipur />} />
<Route path='/bharuch-to-vapi' element={<BharuchToVapi   />} />
<Route path='/bharuch-to-silvassa' element={<BharuchToSilvassa   />} />
<Route path='/bharuch-to-kalyan' element={<BharuchToKalyan   />} />
<Route path='/bharuch-to-bhiwandi' element={<BharuchToBhiwandi   />} />
<Route path='/bharuch-to-navimumbai' element={<BharuchToNaviMumbai   />} />
<Route path='/bharuch-to-badlapur' element={<BharuchToBadlapur   />} />
<Route path='/bharuch-to-ajmer' element={<BharuchToAjmer   />} />
<Route path='/bharuch-to-pune' element={<BharuchToPune />} />
<Route path='/bharuch-to-goa' element={<BharuchToGoa />} />
<Route path='/bharuch-to-rajkot' element={<BharuchToRajkot />} />
<Route path='/bharuch-to-indore' element={<BharuchToIndore />} />




          <Route path='/' element={<Home />} />
          <Route path='/onewaydetails' element={<OneWayDetails />} />
          <Route path='/roundtripdetails' element={<RoundTripDetails />} />
          <Route path='/eightHrsdetails' element={<EightHoursDetails />} />
          <Route path='/tenHrsdetails' element={<TenHoursDetails />} />
          <Route path='/twelveHrsdetails' element={<TwelveHoursDetails />} />
          <Route path='/airportHrsdetails' element={<AirportDetails />} />
          <Route path='/car-booking/:id' element={<OneWay_car_details />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/testimonial' element={<Testimonial />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/demo' element={<DemoInvoice />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/shipping-policy' element={<ShippingPolicy />} />
          <Route path='/terms-condition' element={<Terms_conditions />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/blog/:id' element={<BlogDetails />} />
          <Route path='/router' element={<Route_page />} />


          <Route path='/payment-success' element={<Success />} />
          <Route path='/payment-failure' element={<Failed />} />


        </Routes>
     {!isNoFooter && <Footer />}
    </>
  );
};


export default App


