import React from 'react';
import Info_img from '../../Images/info_img.png';
const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <div className="text-center mb-12">
        <h1 className="text-2xl font-bold text-black">About Hindustan Taxi Service</h1>
      </div>

      <div className="space-y-10">
        <section className="text-lg text-gray-700">
          <p>
            Welcome to <span className="font-semibold">Hindustan Taxi Service</span>, your trusted partner for reliable and comfortable travel across India. With a fleet of modern vehicles and professional drivers, we ensure that every ride is safe, affordable, and on time.
          </p>
        </section>
        <div className=" max-w-[1200px]  ">
        <div>
            <h1 className="text-[22px] leading-10 py-2 font-headingFont text-customfontBlack"> Exploring India, one road trip at a time </h1>
            <div className="grid 2xl:grid-cols-2 smmin:grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-8">
                <img src={Info_img} className="mt-2 border border-gray-500 rounded-2xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] " />
            <div>
            <p className="text-[15px]  font-normalFont text-justify">To us, a road trip is one of the most exhilarating ways to travel the length and breadth of India. There's always something to look at, something to explore and to experience. Because we love travelling by road so much, we've been striving to make sure you have a great experience too. We wanted more of you to go on a road trip, and more of you to experience the same joys of travel that we do. Instead of driving, why not sit back and take our chauffeur driven cabs on your next vacation? We believe that the time you spend on your vacation should be entirely yours. So now, we are in 2000+ cities across India - to help you travel to wherever your heart desires.</p>
            <p className="text-[15px] py-3 font-normalFont lg:block md:hidden text-justify">We love that you're free to stop to breathe in clean air, learn about cultures and taste local food when you travel by cabs. We love that these wholesome experiences make travelling better and enrich our lives. We live for the surprises we find on road trips.</p>
            <p className="text-[15px] py-3 font-normalFont lg:block md:hidden text-justify">No city is too big or too small. We know travellers and wanderers like us are everywhere. You live near Khajuraho, you live near Aleppey, and you live near Alibag and near Tranquebar. We want you to visit them all. Pack your bags every weekend and explore everything there is to see around you.</p>
          
            </div>
            </div>
        </div>
        <p className="text-[15px] py-3 font-normalFont lg:hidden md:block text-justify">We love that you're free to stop to breathe in clean air, learn about cultures and taste local food when you travel by cabs. We love that these wholesome experiences make travelling better and enrich our lives. We live for the surprises we find on road trips.</p>
        <p className="text-[15px] py-3 font-normalFont lg:hidden md:block text-justify">No city is too big or too small. We know travellers and wanderers like us are everywhere. You live near Khajuraho, you live near Aleppey, and you live near Alibag and near Tranquebar. We want you to visit them all. Pack your bags every weekend and explore everything there is to see around you.</p>
       
       
        
    </div>
        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-gray-800">Our Mission</h2>
          <p className="text-gray-700">
            Our mission is to provide top-notch taxi services that cater to all your transportation needs. Whether you're traveling for business, leisure, or daily commuting, Hindustan Taxi Service is committed to delivering the highest level of convenience and safety to our customers.
          </p>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-gray-800">Why Choose Us?</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong className="text-customBlue">Comfortable Rides :</strong> Our vehicles are well-maintained, clean, and equipped with all the amenities to ensure a smooth ride.</li>
            <li><strong className="text-customBlue">24/7 Availability :</strong> We are available around the clock, ready to serve you anytime, anywhere.</li>
            <li><strong className="text-customBlue">Experienced Drivers :</strong> Our drivers are trained professionals with extensive experience in ensuring your safety and comfort.</li>
            <li><strong className="text-customBlue">Affordable Pricing :</strong> Transparent pricing with no hidden fees, ensuring you always get value for your money.</li>
            <li><strong className="text-customBlue">Safety First :</strong> Your safety is our priority. We follow strict safety measures to provide a secure travel experience.</li>
          </ul>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-gray-800">Our Vision</h2>
          <p className="text-gray-700">
            To be the leading taxi service provider in India, known for our commitment to quality, safety, and customer satisfaction. We strive to make every journey enjoyable and hassle-free.
          </p>
        </section>

        <section className="space-y-4">
          <h2 className="text-2xl font-semibold text-gray-800">Contact Us</h2>
          <p className="text-gray-700">
            Have questions or need more information? Reach out to us at:
          </p>
          <ul className="space-y-1">
            <li><span className="font-bold">Email :</span>  contact@hindustantaxiservice.com </li>
            <li><span className="font-bold">Phone :</span> +91 76000 60604  </li>
            <li><span className="font-bold">Office Address :</span>  F-22, Swastik Plaza, Yogichowk, Surat, Gujarat - 395010. </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
